import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../redux/hooks';
import { LoadingScreen } from '../components/loading-screen';

export function ProtectedRoute({ children }: { children: React.ReactNode }) {
  const { restaurant, loading } = useAppSelector((state) => state.restaurant);

  if (loading) {
    return <LoadingScreen />;
  }

  if (!restaurant) {
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
} 