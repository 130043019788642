import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { DashboardLayout } from '../../layouts/dashboard-layout';
import { useAppSelector } from '../../redux/hooks';
// import { menuApi } from '../../services/menu';
import { ProgressiveImage } from '../../components/progressive-image';
import { QRCodeSVG } from 'qrcode.react';
import { toast } from 'react-hot-toast';
import QuickActions from './components/quick-actions';
import ShareMenu from './components/share-menu';
import { analyticsApi } from 'services/analytics';

export default function DashboardPage() {
  const { restaurant } = useAppSelector((state) => state.restaurant);
  
  const { data: menuStats } = useQuery({
    queryKey: ['menuStats'],
    queryFn: () => analyticsApi.getStats(),
  });

  if (!restaurant) return null;

  return (
    <DashboardLayout>
      <div className="container mx-auto p-4">
        {/* Welcome Section */}
        <div className="mb-8">
          <div className="flex items-center gap-4">
            <div className="w-16 h-16 rounded-xl overflow-hidden bg-grey-100 border-2 border-white shadow-lg">
              {restaurant.logoUrl ? (
                <ProgressiveImage
                  media={restaurant.logoUrl}
                  alt={restaurant.name}
                  className="w-full h-full object-cover"
                />
              ) : (
                <div className="w-full h-full flex items-center justify-center bg-primary-lighter">
                  <span className="text-2xl font-bold text-primary-main">
                    {restaurant.name.charAt(0)}
                  </span>
                </div>
              )}
            </div>
            <div>
              <h1 className="text-2xl font-bold text-grey-900">
                Welcome back, {restaurant.name}!
              </h1>
              <p className="text-grey-600">
                Here's what's happening with your restaurant today
              </p>
            </div>
          </div>
        </div>

        {/* Quick Stats */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
          {/* Categories */}
          <div className="bg-white rounded-xl p-6 shadow-sm border border-grey-100">
            <div className="flex items-start justify-between">
              <div>
                <p className="text-grey-600 text-sm">Total Categories</p>
                <h3 className="text-2xl font-bold text-grey-900 mt-1">
                  {menuStats?.totalCategories || 0}
                </h3>
              </div>
              <div className="p-2 bg-primary-lighter rounded-lg">
                <svg className="w-6 h-6 text-primary-main" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 10h16M4 14h16M4 18h16" />
                </svg>
              </div>
            </div>
            <div className="mt-4">
              <Link 
                to="/menu"
                className="text-sm text-primary-main hover:text-primary-dark transition-colors inline-flex items-center gap-1"
              >
                View Menu
                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                </svg>
              </Link>
            </div>
          </div>

          {/* Items */}
          <div className="bg-white rounded-xl p-6 shadow-sm border border-grey-100">
            <div className="flex items-start justify-between">
              <div>
                <p className="text-grey-600 text-sm">Total Items</p>
                <h3 className="text-2xl font-bold text-grey-900 mt-1">
                  {menuStats?.totalItems || 0}
                </h3>
              </div>
              <div className="p-2 bg-success-lighter rounded-lg">
                <svg className="w-6 h-6 text-success-main" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                </svg>
              </div>
            </div>
            <div className="mt-4">
              <div className="flex items-center gap-2 text-sm text-grey-600">
                <span className={`inline-block w-2 h-2 rounded-full ${
                  menuStats?.totalActiveItems === menuStats?.totalItems
                    ? 'bg-success-main'
                    : 'bg-warning-main'
                }`} />
                {menuStats?.totalActiveItems || 0} Active Items
              </div>
            </div>
          </div>

          {/* AR Models */}
          <div className="bg-white rounded-xl p-6 shadow-sm border border-grey-100">
            <div className="flex items-start justify-between">
              <div>
                <p className="text-grey-600 text-sm">AR Models</p>
                <h3 className="text-2xl font-bold text-grey-900 mt-1">
                  {menuStats?.totalArModels || 0}
                </h3>
              </div>
              <div className="p-2 bg-warning-lighter rounded-lg">
                <svg className="w-6 h-6 text-warning-main" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9" />
                </svg>
              </div>
            </div>
            <div className="mt-4">
              <div className="text-sm text-grey-600">
                {((menuStats?.totalArModels || 0) / (menuStats?.totalItems || 1) * 100).toFixed(0)}% of menu items
              </div>
            </div>
          </div>

          {/* Account Status */}
          <div className="bg-white rounded-xl p-6 shadow-sm border border-grey-100">
            <div className="flex items-start justify-between">
              <div>
                <p className="text-grey-600 text-sm">Account Status</p>
                <h3 className="text-2xl font-bold text-grey-900 mt-1">
                  {restaurant.isVerified ? 'Verified' : 'Pending'}
                </h3>
              </div>
              <div className={`p-2 rounded-lg ${
                restaurant.isVerified ? 'bg-success-lighter' : 'bg-warning-lighter'
              }`}>
                <svg className={`w-6 h-6 ${
                  restaurant.isVerified ? 'text-success-main' : 'text-warning-main'
                }`} fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                </svg>
              </div>
            </div>
            <div className="mt-4">
              <Link 
                to="/profile"
                className="text-sm text-primary-main hover:text-primary-dark transition-colors inline-flex items-center gap-1"
              >
                View Profile
                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                </svg>
              </Link>
            </div>
          </div>
        </div>

        {/* Quick Actions */}
        <QuickActions />

        {/* Share Menu Section */}
        <ShareMenu restaurant={restaurant} />
      </div>
    </DashboardLayout>
  );
}