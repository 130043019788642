import api from './api';
import type { ApiResponse } from '../types/api';
import type { MenuCategory } from '../types/menu';
import { Media } from 'types';

interface CategoryData {
  name: string;
  description: string;
  imageUrl: Media | null;
  isActive: boolean;
}

export const categoryApi = {
  getAll: async (): Promise<MenuCategory[]> => {
    const response = await api.get<ApiResponse<MenuCategory[]>>('/api/v1/category');
    return response.data.data;
  },

  create: async (data: CategoryData): Promise<MenuCategory> => {
    const response = await api.post<ApiResponse<MenuCategory>>('/api/v1/category', data);
    return response.data.data;
  },

  update: async (id: string, data: CategoryData): Promise<MenuCategory> => {
    const updateData = {
      id,
      name: data.name,
      description: data.description,
      imageUrl: data.imageUrl,
      isActive: data.isActive,
    };

    const response = await api.put<ApiResponse<MenuCategory>>('/api/v1/category', updateData);
    return response.data.data;
  },

  delete: async (id: string): Promise<void> => {
    await api.delete<ApiResponse<void>>(`/api/v1/category/${id}`);
  },
}; 