import React, { useState, useEffect } from 'react';
import { TopNavbar } from './components/top-navbar';
import { SideNavbar } from './components/side-navbar';

export function DashboardLayout({ children }: { children: React.ReactNode }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  // Handle window resize and set mobile state
  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth < 1024;
      setIsMobile(mobile);
      if (mobile) {
        setIsSidebarOpen(false);
      } else {
        setIsSidebarOpen(true);
      }
    };

    // Initial check
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="min-h-screen bg-grey-100">
      {/* Overlay */}
      {isMobile && isSidebarOpen && (
        <div
          className="fixed inset-0 bg-grey-800 bg-opacity-50 z-40 transition-opacity lg:hidden"
          onClick={() => setIsSidebarOpen(false)}
        />
      )}

      {/* Sidebar */}
      <SideNavbar isOpen={isSidebarOpen} isMobile={isMobile} />
      
      {/* Main Content */}
      <div
        className={`transition-all duration-200 ${
          isSidebarOpen && !isMobile ? 'lg:ml-64' : ''
        }`}
      >
        <TopNavbar onMenuClick={toggleSidebar} />
        <main className="p-4 lg:p-6 mt-16">
          {children}
        </main>
      </div>
    </div>
  );
} 