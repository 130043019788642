import React from 'react';

interface RestaurantIconProps {
  className?: string;
}

export function RestaurantIcon({ className = '' }: RestaurantIconProps) {
  return (
    <svg 
      className={className}
      viewBox="0 0 24 24" 
      fill="currentColor"
    >
      <path d="M21.9 5.6c-.1-.3-.3-.5-.6-.6L12 2 2.7 5c-.3.1-.5.3-.6.6-.1.2-.1.5 0 .7l.9 2.4v4.8c0 .2 0 .3.1.4L12 21l8.9-7.1c.1-.1.1-.2.1-.4V8.7l.9-2.4c.1-.2.1-.5 0-.7zM19 13.4l-7 5.6-7-5.6V9.2l6.2 2.3c.5.2 1.1.2 1.6 0l6.2-2.3v4.2zm.1-5.8l-7.4 2.7c-.2.1-.4.1-.6 0L4.1 7.6 12 5.2l7.9 2.4h.1z"/>
    </svg>
  );
} 