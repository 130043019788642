import React, { useState, useEffect } from 'react';
import { Dialog, Transition, Switch } from '@headlessui/react';
import { Fragment } from 'react';
import type { MenuCategory } from '../../../types/menu';
import type { Media } from '../../../types/media';
import { ProgressiveImage } from '../../../components/progressive-image';
import { uploadApi } from '../../../services/upload';
import { toast } from 'react-hot-toast';

interface CategoryDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: { name: string; description: string; imageUrl: Media | null; isActive: boolean }) => Promise<void>;
  category?: MenuCategory;
}

export function CategoryDialog({ open, onClose, onSubmit, category }: CategoryDialogProps) {
  const [imageUploading, setImageUploading] = useState(false);
  const [imageUrl, setImageUrl] = useState<Media | null>(category?.imageUrl || null);
  const [isActive, setIsActive] = useState(category?.isActive ?? true);
  const [name, setName] = useState(category?.name || '');
  const [description, setDescription] = useState(category?.description || '');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      try {
        setImageUploading(true);
        const uploadedImage = await uploadApi.uploadImage(file);
        setImageUrl(uploadedImage);
      } catch (error) {
        toast.error('Failed to upload image');
      } finally {
        setImageUploading(false);
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!name.trim()) {
      toast.error('Category name is required');
      return;
    }

    try {
      setIsSubmitting(true);
      await onSubmit({
        name: name.trim(),
        description: description.trim(),
        imageUrl,
        isActive,
      });
      
      // Reset form
      setName('');
      setDescription('');
      setImageUrl(null);
      setIsActive(true);
      
      onClose();
      toast.success(category ? 'Category updated successfully' : 'Category created successfully');
    } catch (error) {
      toast.error(error instanceof Error ? error.message : 'Failed to save category');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Add escape key handler
  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && !isSubmitting && !imageUploading) {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscape);
    return () => document.removeEventListener('keydown', handleEscape);
  }, [onClose, isSubmitting, imageUploading]);

  // Reset form when dialog opens
  useEffect(() => {
    if (open) {
      setName(category?.name || '');
      setDescription(category?.description || '');
      setImageUrl(category?.imageUrl || null);
      setIsActive(category?.isActive ?? true);
    }
  }, [open, category]);

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {/* Prevent close */}}>
        {/* Backdrop */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-grey-800 bg-opacity-50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-xl font-semibold text-grey-900">
                  {category ? 'Edit Category' : 'Add New Category'}
                </Dialog.Title>

                <form className="mt-6 space-y-6" onSubmit={handleSubmit}>
                  {/* Image Upload Section */}
                  <div>
                    <label className="block text-sm font-medium text-grey-700 mb-2">
                      Category Image
                    </label>
                    <div className="flex flex-col items-center space-y-4">
                      {/* Image Preview */}
                      <div className="w-full aspect-video rounded-lg overflow-hidden bg-grey-100 border-2 border-dashed border-grey-300 relative group">
                        {imageUrl ? (
                          <div className="w-full h-full relative group">
                            <ProgressiveImage
                              media={imageUrl}
                              alt="Category preview"
                              className="w-full h-full object-cover"
                            />
                            {/* Overlay with change button */}
                            <div className="absolute inset-0 bg-grey-900 bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
                              <button
                                type="button"
                                onClick={() => setImageUrl(null)}
                                className="bg-white text-grey-800 px-4 py-2 rounded-lg text-sm font-medium hover:bg-grey-100 transition-colors"
                              >
                                Change Image
                              </button>
                            </div>
                          </div>
                        ) : (
                          <label className="w-full h-full flex flex-col items-center justify-center cursor-pointer p-4">
                            <svg className="w-12 h-12 text-grey-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                            </svg>
                            <p className="mt-2 text-sm text-grey-500">Click to upload image</p>
                            <p className="text-xs text-grey-400">PNG, JPG up to 5MB</p>
                            <input
                              type="file"
                              className="hidden"
                              accept="image/*"
                              onChange={handleFileSelect}
                              disabled={imageUploading}
                            />
                          </label>
                        )}
                        {/* Loading Overlay */}
                        {imageUploading && (
                          <div className="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center">
                            <div className="animate-spin rounded-full h-8 w-8 border-2 border-primary-main border-t-transparent"></div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Name Field */}
                  <div>
                    <label htmlFor="name" className="block text-sm font-medium text-grey-700 mb-2">
                      Category Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="w-full px-3 py-2 border border-grey-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-light focus:border-primary-main transition-colors"
                      placeholder="Enter category name"
                      required
                    />
                  </div>

                  {/* Description Field */}
                  <div>
                    <label htmlFor="description" className="block text-sm font-medium text-grey-700 mb-2">
                      Description
                    </label>
                    <textarea
                      id="description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      rows={3}
                      className="w-full px-3 py-2 border border-grey-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-light focus:border-primary-main transition-colors resize-none"
                      placeholder="Enter category description"
                    />
                  </div>

                  {/* Active Status Toggle */}
                  <div className="flex items-center justify-between">
                    <div>
                      <h4 className="text-sm font-medium text-grey-700">Active Status</h4>
                      <p className="text-sm text-grey-500">Category will be visible to customers</p>
                    </div>
                    <Switch
                      checked={isActive}
                      onChange={setIsActive}
                      className={`${
                        isActive ? 'bg-primary-main' : 'bg-grey-300'
                      } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-primary-light focus:ring-offset-2`}
                    >
                      <span
                        className={`${
                          isActive ? 'translate-x-6' : 'translate-x-1'
                        } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                      />
                    </Switch>
                  </div>

                  {/* Action Buttons */}
                  <div className="flex justify-end gap-3 mt-8">
                    <button
                      type="button"
                      onClick={onClose}
                      disabled={isSubmitting}
                      className="px-4 py-2 text-sm font-medium text-grey-700 hover:bg-grey-100 rounded-lg transition-colors disabled:opacity-50"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      disabled={isSubmitting || imageUploading}
                      className="px-4 py-2 text-sm font-medium text-white bg-primary-main hover:bg-primary-dark rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      {isSubmitting ? 'Saving...' : category ? 'Save Changes' : 'Add Category'}
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
} 