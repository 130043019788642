import React, { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { ProgressiveImage } from '../../../components/progressive-image';
import type { Restaurant } from '../../../types';
import type { Media } from '../../../types/media';
import { uploadApi } from '../../../services/upload';
import { toast } from 'react-hot-toast';

interface EditProfileDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: Partial<Restaurant>) => void;
  restaurant: Restaurant;
}

export function EditProfileDialog({ open, onClose, onSubmit, restaurant }: EditProfileDialogProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [imageUploading, setImageUploading] = useState(false);
  
  const [formData, setFormData] = React.useState({
    name: restaurant.name,
    phone: restaurant.phone,
    contactPersonName: restaurant.contactPersonName,
    address: restaurant.address,
    city: restaurant.city,
    state: restaurant.state,
    pincode: restaurant.pincode,
    country: restaurant.country,
    logoUrl: restaurant.logoUrl,
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isSubmitting || imageUploading) return;

    setIsSubmitting(true);
    try {
      await onSubmit({
        ...formData,
        id: restaurant.id,
        email: restaurant.email,
        isVerified: restaurant.isVerified,
        createdAt: restaurant.createdAt,
        updatedAt: restaurant.updatedAt,
      });
    } catch (error) {
      // Error is handled by the parent component
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    setImageUploading(true);
    try {
      const uploadedImage = await uploadApi.uploadImage(file);
      setFormData(prev => ({
        ...prev,
        logoUrl: uploadedImage,
      }));
    } catch (error) {
      toast.error('Failed to upload image');
    } finally {
      setImageUploading(false);
    }
  };

  const handleRemoveImage = () => {
    setFormData(prev => ({
      ...prev,
      logoUrl: null,
    }));
  };

  return (
    <Dialog open={open} onClose={onClose} className="relative z-50">
      {/* Background Overlay */}
      <div className="fixed inset-0 bg-grey-900/75" aria-hidden="true" />

      {/* Full-screen container */}
      <div className="fixed inset-0 flex items-center justify-center p-4 overflow-y-auto">
        <Dialog.Panel className="mx-auto w-full max-w-xl bg-white rounded-xl shadow-xl my-8">
          {/* Dialog Header - Fixed */}
          <div className="px-6 py-4 border-b border-grey-200 sticky top-0 bg-white z-10">
            <div className="flex items-center justify-between">
              <Dialog.Title className="text-xl font-semibold text-grey-900">
                Edit Profile
              </Dialog.Title>
              <button
                onClick={onClose}
                className="text-grey-500 hover:text-grey-700 transition-colors"
              >
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>

          {/* Dialog Content - Scrollable */}
          <form onSubmit={handleSubmit}>
            <div className="px-6 py-6 max-h-[calc(100vh-16rem)] overflow-y-auto">
              <div className="space-y-6">
                {/* Logo Upload Section */}
                <div>
                  <h3 className="text-sm font-medium text-grey-900 mb-4">Restaurant Logo</h3>
                  <div className="flex items-center gap-6">
                    {/* Preview Image */}
                    <div className="w-24 h-24 rounded-xl bg-grey-50 overflow-hidden flex items-center justify-center border-2 border-grey-200">
                      {imageUploading ? (
                        <div className="w-6 h-6 border-2 border-primary-main border-t-transparent rounded-full animate-spin" />
                      ) : formData.logoUrl ? (
                        <div className="w-full h-full relative group">
                          <ProgressiveImage
                            media={formData.logoUrl}
                            alt={formData.name}
                            className="w-full h-full object-contain"
                          />
                          {/* Hover overlay */}
                          <div className="absolute inset-0 bg-grey-900/0 group-hover:bg-grey-900/10 transition-colors flex items-center justify-center">
                            <div className="opacity-0 group-hover:opacity-100 transition-opacity">
                              <label
                                htmlFor="logo"
                                className="cursor-pointer p-2 rounded-full bg-white/90 hover:bg-white text-grey-700"
                                title="Change Image"
                              >
                                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" />
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 13a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                              </label>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <label 
                          htmlFor="logo" 
                          className="w-full h-full flex flex-col items-center justify-center cursor-pointer hover:bg-grey-100 transition-colors"
                        >
                          <svg className="w-8 h-8 text-grey-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                          </svg>
                          <span className="mt-1 text-xs text-grey-500">Add Logo</span>
                        </label>
                      )}
                    </div>

                    {/* Upload Controls */}
                    <div className="flex-1 space-y-2">
                      <input
                        type="file"
                        id="logo"
                        accept="image/*"
                        onChange={handleImageChange}
                        disabled={imageUploading}
                        className="hidden"
                      />
                      <label
                        htmlFor="logo"
                        className={`inline-flex items-center px-4 py-2 bg-white border border-grey-300 rounded-lg text-sm font-medium text-grey-700 hover:bg-grey-50 cursor-pointer ${imageUploading ? 'opacity-50 cursor-not-allowed' : ''}`}
                      >
                        <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                        </svg>
                        {imageUploading ? 'Uploading...' : 'Choose Image'}
                      </label>
                      {formData.logoUrl && !imageUploading && (
                        <button
                          type="button"
                          onClick={handleRemoveImage}
                          className="inline-flex items-center px-4 py-2 bg-error-lighter border border-error-main rounded-lg text-sm font-medium text-error-main hover:bg-error-light"
                        >
                          <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                          </svg>
                          Remove Image
                        </button>
                      )}
                      <p className="text-sm text-grey-500">
                        Recommended: Square image, at least 200x200px
                      </p>
                    </div>
                  </div>
                </div>

                {/* Basic Info Section */}
                <div>
                  <h3 className="text-sm font-medium text-grey-900 mb-4">Basic Information</h3>
                  <div className="space-y-4">
                    {/* Restaurant Name */}
                    <div>
                      <label htmlFor="name" className="block text-sm font-medium text-grey-700 mb-1">
                        Restaurant Name
                      </label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="w-full px-4 py-2 rounded-lg border border-grey-300 focus:ring-2 focus:ring-primary-light focus:border-primary-main"
                        required
                      />
                    </div>

                    {/* Contact Person Name */}
                    <div>
                      <label htmlFor="contactPersonName" className="block text-sm font-medium text-grey-700 mb-1">
                        Contact Person Name
                      </label>
                      <input
                        type="text"
                        id="contactPersonName"
                        name="contactPersonName"
                        value={formData.contactPersonName}
                        onChange={handleChange}
                        className="w-full px-4 py-2 rounded-lg border border-grey-300 focus:ring-2 focus:ring-primary-light focus:border-primary-main"
                        required
                      />
                    </div>
                  </div>
                </div>

                {/* Contact Info Section */}
                <div>
                  <h3 className="text-sm font-medium text-grey-900 mb-4">Contact Information</h3>
                  <div className="space-y-4">
                    {/* Phone Number */}
                    <div>
                      <label htmlFor="phone" className="block text-sm font-medium text-grey-700 mb-1">
                        Phone Number
                      </label>
                      <input
                        type="tel"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        className="w-full px-4 py-2 rounded-lg border border-grey-300 focus:ring-2 focus:ring-primary-light focus:border-primary-main"
                        required
                      />
                    </div>

                    {/* Email (Read-only) */}
                    <div>
                      <label className="block text-sm font-medium text-grey-700 mb-1">
                        Email Address
                      </label>
                      <input
                        type="email"
                        value={restaurant.email}
                        readOnly
                        className="w-full px-4 py-2 rounded-lg border border-grey-300 bg-grey-50 text-grey-900"
                      />
                      <p className="mt-1 text-sm text-grey-500">
                        Email address cannot be changed
                      </p>
                    </div>
                  </div>
                </div>

                {/* Address Section */}
                <div>
                  <h3 className="text-sm font-medium text-grey-900 mb-4">Address Details</h3>
                  <div className="space-y-4">
                    {/* Street Address */}
                    <div>
                      <label htmlFor="address" className="block text-sm font-medium text-grey-700 mb-1">
                        Street Address
                      </label>
                      <textarea
                        id="address"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        rows={2}
                        className="w-full px-4 py-2 rounded-lg border border-grey-300 focus:ring-2 focus:ring-primary-light focus:border-primary-main"
                        required
                      />
                    </div>

                    {/* City & State */}
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <label htmlFor="city" className="block text-sm font-medium text-grey-700 mb-1">
                          City
                        </label>
                        <input
                          type="text"
                          id="city"
                          name="city"
                          value={formData.city}
                          onChange={handleChange}
                          className="w-full px-4 py-2 rounded-lg border border-grey-300 focus:ring-2 focus:ring-primary-light focus:border-primary-main"
                          required
                        />
                      </div>
                      <div>
                        <label htmlFor="state" className="block text-sm font-medium text-grey-700 mb-1">
                          State
                        </label>
                        <input
                          type="text"
                          id="state"
                          name="state"
                          value={formData.state}
                          onChange={handleChange}
                          className="w-full px-4 py-2 rounded-lg border border-grey-300 focus:ring-2 focus:ring-primary-light focus:border-primary-main"
                          required
                        />
                      </div>
                    </div>

                    {/* Pincode & Country */}
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <label htmlFor="pincode" className="block text-sm font-medium text-grey-700 mb-1">
                          Pincode
                        </label>
                        <input
                          type="text"
                          id="pincode"
                          name="pincode"
                          value={formData.pincode}
                          onChange={handleChange}
                          className="w-full px-4 py-2 rounded-lg border border-grey-300 focus:ring-2 focus:ring-primary-light focus:border-primary-main"
                          required
                        />
                      </div>
                      <div>
                        <label htmlFor="country" className="block text-sm font-medium text-grey-700 mb-1">
                          Country
                        </label>
                        <input
                          type="text"
                          id="country"
                          name="country"
                          value={formData.country}
                          onChange={handleChange}
                          className="w-full px-4 py-2 rounded-lg border border-grey-300 focus:ring-2 focus:ring-primary-light focus:border-primary-main"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Dialog Footer - Fixed */}
            <div className="px-6 py-4 bg-grey-50 border-t border-grey-200 rounded-b-xl sticky bottom-0">
              <div className="flex justify-end gap-3">
                <button
                  type="button"
                  onClick={onClose}
                  disabled={isSubmitting || imageUploading}
                  className="px-4 py-2 text-grey-700 bg-white border border-grey-300 rounded-lg hover:bg-grey-50 transition-colors disabled:opacity-50"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting || imageUploading}
                  className="px-4 py-2 bg-primary-main text-white rounded-lg hover:bg-primary-dark transition-colors disabled:opacity-50 flex items-center gap-2"
                >
                  {isSubmitting ? (
                    <>
                      <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
                      Saving...
                    </>
                  ) : (
                    'Save Changes'
                  )}
                </button>
              </div>
            </div>
          </form>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
} 