import { ApiResponse } from "types/api";
import api from "./api";


export const analyticsApi = {
  getStats: async () => {
    const response = await api.get<ApiResponse<{
        totalCategories: number;
        totalItems: number;
        totalActiveItems: number;
        totalArModels: number;
    }>>('/api/v1/analytics/dashboard');
    return response.data.data;
  },
};
