import React, { useState, useEffect } from 'react';
import { Dialog, Transition, Switch } from '@headlessui/react';
import { Fragment } from 'react';
import type { MenuItem } from '../../../../types/menu-item';
import type { Media } from '../../../../types/media';
import { ProgressiveImage } from '../../../../components/progressive-image';
import { uploadApi } from '../../../../services/upload';
import { toast } from 'react-hot-toast';

interface MenuItemDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: Partial<MenuItem>) => Promise<void>;
  item?: MenuItem;
}

export function MenuItemDialog({ open, onClose, onSubmit, item }: MenuItemDialogProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [imageUploading, setImageUploading] = useState(false);
  const [uploadingImages, setUploadingImages] = useState<number[]>([]);
  const [isAnyImageUploading, setIsAnyImageUploading] = useState(false);
  
  // Form state
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    price: 0,
    calories: 0,
    imageUrls: [] as Media[],
    arModelUrl: null as Media | null,
    ingredients: '',
    allergens: '',
    isVegetarian: false,
    isVegan: false,
    isGlutenFree: false,
    isActive: true,
  });

  // Reset form when dialog opens/closes or item changes
  useEffect(() => {
    if (open && item) {
      setFormData({
        name: item.name,
        description: item.description,
        price: item.price,
        calories: item.calories,
        imageUrls: item.imageUrls || [],
        arModelUrl: item.arModelUrl,
        ingredients: item.ingredients,
        allergens: item.allergens,
        isVegetarian: item.isVegetarian,
        isVegan: item.isVegan,
        isGlutenFree: item.isGlutenFree,
        isActive: item.isActive,
      });
    } else if (!open) {
      setFormData({
        name: '',
        description: '',
        price: 0,
        calories: 0,
        imageUrls: [],
        arModelUrl: null,
        ingredients: '',
        allergens: '',
        isVegetarian: false,
        isVegan: false,
        isGlutenFree: false,
        isActive: true,
      });
    }
  }, [open, item]);

  // Update image uploading state
  useEffect(() => {
    setIsAnyImageUploading(imageUploading || uploadingImages.length > 0);
  }, [imageUploading, uploadingImages]);

  // Handle dialog close attempt
  const handleClose = () => {
    if (isAnyImageUploading) {
      toast.error('Please wait for images to finish uploading');
      return;
    }
    onClose();
  };

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    if (files.length === 0) return;

    try {
      setImageUploading(true);
      const newPlaceholders = files.map((_, index) => formData.imageUrls.length + index);
      setUploadingImages(newPlaceholders);

      const uploadPromises = files.map(file => uploadApi.uploadImage(file));
      const uploadedImages = await Promise.all(uploadPromises);
      
      setFormData(prev => ({
        ...prev,
        imageUrls: [...prev.imageUrls, ...uploadedImages],
      }));
    } catch (error) {
      toast.error('Failed to upload images');
    } finally {
      setImageUploading(false);
      setUploadingImages([]);
    }
  };

  const handleArModelUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    // Validate file type
    if (!file.type.includes('png')) {
      toast.error('Please upload a PNG file');
      return;
    }

    try {
      setImageUploading(true);
      const uploadedModel = await uploadApi.uploadImage(file);
      setFormData(prev => ({
        ...prev,
        arModelUrl: uploadedModel,
      }));
    } catch (error) {
      toast.error('Failed to upload AR model');
    } finally {
      setImageUploading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Prevent submission if images are uploading
    if (isAnyImageUploading) {
      toast.error('Please wait for images to finish uploading');
      return;
    }

    if (!formData.name.trim()) {
      toast.error('Name is required');
      return;
    }

    try {
      setIsSubmitting(true);
      await onSubmit(formData);
      handleClose();
      toast.success(item ? 'Item updated successfully' : 'Item created successfully');
    } catch (error) {
      toast.error('Failed to save item');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleIngredientChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // Don't split the value while typing, just store the raw input
    setFormData(prev => ({
      ...prev,
      ingredients: value,
    }));
  };

  const handleAllergenChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // Don't split the value while typing, just store the raw input
    setFormData(prev => ({
      ...prev,
      allergens: value,
    }));
  };

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-grey-800 bg-opacity-50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-xl font-semibold text-grey-900 mb-6">
                  {item ? 'Edit Menu Item' : 'Add Menu Item'}
                </Dialog.Title>

                <form onSubmit={handleSubmit} className="mt-6 space-y-6">
                  {/* Images Section */}
                  <div>
                    <label className="block text-sm font-medium text-grey-700 mb-2">
                      Images
                    </label>
                    <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                      {formData.imageUrls.map((image, index) => (
                        <div key={index} className="aspect-video relative rounded-lg overflow-hidden bg-grey-100">
                          <ProgressiveImage
                            media={image}
                            alt={`Item image ${index + 1}`}
                            className="w-full h-full object-cover"
                          />
                          <button
                            type="button"
                            onClick={() => {
                              setFormData(prev => ({
                                ...prev,
                                imageUrls: prev.imageUrls.filter((_, i) => i !== index),
                              }));
                            }}
                            className="absolute top-2 right-2 p-1 rounded-full bg-grey-900/50 text-white hover:bg-grey-900/70 transition-colors backdrop-blur-sm"
                          >
                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                          </button>
                        </div>
                      ))}

                      {uploadingImages.map((_, index) => (
                        <div key={`loading-${index}`} className="aspect-video relative rounded-lg overflow-hidden bg-grey-100 animate-pulse">
                          <div className="absolute inset-0 flex flex-col items-center justify-center gap-2">
                            <div className="w-8 h-8 border-3 border-primary-light border-t-primary-main rounded-full animate-spin" />
                            <span className="text-sm text-grey-600">Uploading...</span>
                          </div>
                        </div>
                      ))}

                      <label className={`aspect-video relative rounded-lg overflow-hidden border-2 border-dashed ${
                        'border-grey-300 hover:border-primary-main'
                      } transition-colors cursor-pointer`}>
                        <input
                          type="file"
                          accept="image/*"
                          multiple
                          onChange={handleImageUpload}
                          className="hidden"
                        />
                        <div className="absolute inset-0 flex flex-col items-center justify-center p-4 text-center">
                            <>
                              <svg className="w-8 h-8 text-grey-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                              </svg>
                              <span className="mt-2 text-sm text-grey-500">Add Image</span>
                            </>
                        </div>
                      </label>
                    </div>
                  </div>

                  {/* Basic Info */}
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div>
                      <label htmlFor="name" className="block text-sm font-medium text-grey-700 mb-2">
                        Name
                      </label>
                      <input
                        type="text"
                        id="name"
                        value={formData.name}
                        onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                        className="w-full px-3 py-2 border border-grey-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-light focus:border-primary-main"
                        placeholder="Enter item name"
                        required
                      />
                    </div>

                    <div>
                      <label htmlFor="price" className="block text-sm font-medium text-grey-700 mb-2">
                        Price (₹)
                      </label>
                      <input
                        type="number"
                        id="price"
                        value={formData.price}
                        onChange={(e) => setFormData(prev => ({ ...prev, price: Number(e.target.value) }))}
                        className="w-full px-3 py-2 border border-grey-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-light focus:border-primary-main"
                        placeholder="Enter price"
                        min="0"
                        step="0.01"
                        required
                      />
                    </div>
                  </div>

                  {/* Description */}
                  <div>
                    <label htmlFor="description" className="block text-sm font-medium text-grey-700 mb-2">
                      Description
                    </label>
                    <textarea
                      id="description"
                      value={formData.description}
                      onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                      rows={3}
                      className="w-full px-3 py-2 border border-grey-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-light focus:border-primary-main resize-none"
                      placeholder="Enter item description"
                    />
                  </div>

                  {/* Ingredients */}
                  <div>
                    <label htmlFor="ingredients" className="block text-sm font-medium text-grey-700 mb-2">
                      Ingredients (comma-separated)
                    </label>
                    <input
                      type="text"
                      id="ingredients"
                      value={formData.ingredients}
                      onChange={handleIngredientChange}
                      className="w-full px-3 py-2 border border-grey-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-light focus:border-primary-main"
                      placeholder="e.g., Tomato, Cheese, Basil"
                    />
                  </div>

                  {/* Allergens */}
                  <div>
                    <label htmlFor="allergens" className="block text-sm font-medium text-grey-700 mb-2">
                      Allergens (comma-separated)
                    </label>
                    <input
                      type="text"
                      id="allergens"
                      value={formData.allergens}
                      onChange={handleAllergenChange}
                      className="w-full px-3 py-2 border border-grey-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-light focus:border-primary-main"
                      placeholder="e.g., Milk, Gluten, Nuts"
                    />
                  </div>

                  {/* Dietary Info */}
                  <div className="space-y-4">
                    <h4 className="font-medium text-grey-900">Dietary Information</h4>
                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                      <div className="flex items-center justify-between bg-grey-50 p-3 rounded-lg">
                        <span className="text-sm font-medium text-grey-700">Vegetarian</span>
                        <Switch
                          checked={formData.isVegetarian}
                          onChange={(checked) => setFormData(prev => ({ ...prev, isVegetarian: checked }))}
                          className={`${
                            formData.isVegetarian ? 'bg-success-main' : 'bg-grey-300'
                          } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-success-light focus:ring-offset-2`}
                        >
                          <span
                            className={`${
                              formData.isVegetarian ? 'translate-x-6' : 'translate-x-1'
                            } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                          />
                        </Switch>
                      </div>

                      <div className="flex items-center justify-between bg-grey-50 p-3 rounded-lg">
                        <span className="text-sm font-medium text-grey-700">Vegan</span>
                        <Switch
                          checked={formData.isVegan}
                          onChange={(checked) => setFormData(prev => ({ ...prev, isVegan: checked }))}
                          className={`${
                            formData.isVegan ? 'bg-success-main' : 'bg-grey-300'
                          } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-success-light focus:ring-offset-2`}
                        >
                          <span
                            className={`${
                              formData.isVegan ? 'translate-x-6' : 'translate-x-1'
                            } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                          />
                        </Switch>
                      </div>

                      <div className="flex items-center justify-between bg-grey-50 p-3 rounded-lg">
                        <span className="text-sm font-medium text-grey-700">Gluten Free</span>
                        <Switch
                          checked={formData.isGlutenFree}
                          onChange={(checked) => setFormData(prev => ({ ...prev, isGlutenFree: checked }))}
                          className={`${
                            formData.isGlutenFree ? 'bg-success-main' : 'bg-grey-300'
                          } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-success-light focus:ring-offset-2`}
                        >
                          <span
                            className={`${
                              formData.isGlutenFree ? 'translate-x-6' : 'translate-x-1'
                            } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                          />
                        </Switch>
                      </div>
                    </div>
                  </div>

                  {/* Additional Info */}
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div>
                      <label htmlFor="calories" className="block text-sm font-medium text-grey-700 mb-2">
                        Calories
                      </label>
                      <input
                        type="number"
                        id="calories"
                        value={formData.calories}
                        onChange={(e) => setFormData(prev => ({ ...prev, calories: Number(e.target.value) }))}
                        className="w-full px-3 py-2 border border-grey-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-light focus:border-primary-main"
                        placeholder="Enter calories"
                        min="0"
                      />
                    </div>

                    <div className="flex items-center justify-between bg-grey-50 p-3 rounded-lg">
                      <span className="text-sm font-medium text-grey-700">Active Status</span>
                      <Switch
                        checked={formData.isActive}
                        onChange={(checked) => setFormData(prev => ({ ...prev, isActive: checked }))}
                        className={`${
                          formData.isActive ? 'bg-primary-main' : 'bg-grey-300'
                        } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-primary-light focus:ring-offset-2`}
                      >
                        <span
                          className={`${
                            formData.isActive ? 'translate-x-6' : 'translate-x-1'
                          } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                        />
                      </Switch>
                    </div>
                  </div>

                  {/* AR Model Upload */}
                  <div>
                    <div className="flex items-center justify-between mb-2">
                      <label className="block text-sm font-medium text-grey-700">
                        AR Model Image
                      </label>
                      <a 
                        href="https://www.remove.bg" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="text-xs text-primary-main hover:text-primary-dark flex items-center gap-1"
                      >
                        Remove background
                        <svg className="w-3 h-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                        </svg>
                      </a>
                    </div>
                    <p className="text-xs text-grey-500 mb-3">
                      Upload a PNG image with transparent background for better AR experience
                    </p>
                    
                    <div className="flex items-center gap-4">
                      {/* Current AR Model Preview */}
                      {formData.arModelUrl ? (
                        <div className="relative w-32 h-32 rounded-lg overflow-hidden bg-grey-100">
                          <ProgressiveImage
                            media={formData.arModelUrl}
                            alt="AR Model"
                            className="w-full h-full object-contain"
                          />
                          <button
                            type="button"
                            onClick={() => {
                              setFormData(prev => ({
                                ...prev,
                                arModelUrl: null,
                              }));
                            }}
                            className="absolute top-2 right-2 p-1 rounded-full bg-grey-900/50 text-white hover:bg-grey-900/70 transition-colors backdrop-blur-sm"
                          >
                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                          </button>
                        </div>
                      ) : (
                        <label className="w-32 h-32 relative rounded-lg overflow-hidden border-2 border-dashed border-grey-300 hover:border-primary-main transition-colors cursor-pointer bg-grey-50 flex flex-col items-center justify-center">
                          <input
                            type="file"
                            accept=".png"
                            onChange={handleArModelUpload}
                            className="hidden"
                          />
                          <svg 
                            className="w-8 h-8 text-grey-400 mb-2" 
                            fill="none" 
                            stroke="currentColor" 
                            viewBox="0 0 24 24"
                          >
                            <path 
                              strokeLinecap="round" 
                              strokeLinejoin="round" 
                              strokeWidth={2} 
                              d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" 
                            />
                          </svg>
                          <span className="text-xs text-grey-500">Upload AR Model</span>
                        </label>
                      )}

                      {/* Loading State */}
                      {imageUploading && !formData.arModelUrl && (
                        <div className="w-32 h-32 rounded-lg bg-grey-100 animate-pulse flex items-center justify-center">
                          <div className="w-8 h-8 border-3 border-primary-light border-t-primary-main rounded-full animate-spin" />
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Action Buttons */}
                  <div className="flex justify-end gap-3 mt-8">
                    <button
                      type="button"
                      onClick={handleClose}
                      disabled={isSubmitting || isAnyImageUploading}
                      className="px-4 py-2 text-sm font-medium text-grey-700 hover:bg-grey-100 rounded-lg transition-colors disabled:opacity-50"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      disabled={isSubmitting || isAnyImageUploading}
                      className="px-4 py-2 text-sm font-medium text-white bg-primary-main hover:bg-primary-dark rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2"
                    >
                      {isSubmitting ? (
                        <>
                          <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
                          Saving...
                        </>
                      ) : isAnyImageUploading ? (
                        <>
                          <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
                          Uploading...
                        </>
                      ) : (
                        'Save Item'
                      )}
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
} 