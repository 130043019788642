import React, { useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { setRestaurant } from '../../redux/slices/restaurant';
import { restaurantApi } from '../../services/restaurant';

export default function VerifyEmailPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { restaurant } = useAppSelector((state) => state.restaurant);

  let flag = false;

  // Verify email mutation
  const verifyMutation = useMutation({
    mutationFn: restaurantApi.verifyEmail,
    onSuccess: () => {
      // Update restaurant state with verified status
      if (restaurant) {
        dispatch(setRestaurant({ ...restaurant, isVerified: true }));
      }
      toast.success('Email verified successfully!');
      navigate('/dashboard');
    },
    onError: (error: any) => {
      toast.error(error.response?.data?.error || 'Failed to verify email. Please try again.');
    },
  });

  // Resend verification email mutation
  const resendMutation = useMutation({
    mutationFn: restaurantApi.resendVerification,
    onSuccess: () => {
      toast.success('Verification email sent successfully!');
    },
    onError: (error: any) => {
      toast.error(error.response?.data?.error || 'Failed to send verification email.');
    },
  });

  // Check for verification token in URL
  useEffect(() => {
    const token = searchParams.get('token');
    if (token && flag) return
    if (token && !flag) {
      flag = true;
      verifyMutation.mutate(token);
    }
    
  }, [searchParams]);

  const handleResendEmail = () => {
    resendMutation.mutate();
  };

  // Show loading state while verifying
  if (verifyMutation.isPending) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-grey-100">
        <div className="text-center space-y-4">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary-main mx-auto"></div>
          <p className="text-grey-600">Verifying your email...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-grey-100 px-4 py-12 sm:px-6 lg:px-8 bg-gradient-to-br from-grey-50 to-grey-200">
      <div className="max-w-md w-full space-y-8">
        <div className="bg-white rounded-xl shadow-2xl p-8 space-y-8">
          {/* Logo and Title */}
          <div className="text-center space-y-4">
            <div className="flex flex-col items-center">
              <img
                className="h-16 w-auto"
                src="/logo.png"
                alt="Meneuar"
              />
              <h1 className="mt-4 text-4xl font-bold bg-gradient-to-r from-grey-800 to-grey-600 bg-clip-text text-transparent [-webkit-background-clip:text]">
                Verify Your Email
              </h1>
            </div>
          </div>

          {/* Verification Message */}
          <div className="text-center space-y-4">
            <div className="flex justify-center">
              <div className="h-24 w-24 rounded-full bg-primary-lighter flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-primary-main" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 19v-8.93a2 2 0 01.89-1.664l7-4.666a2 2 0 012.22 0l7 4.666A2 2 0 0121 10.07V19M3 19a2 2 0 002 2h14a2 2 0 002-2M3 19l6.75-4.5M21 19l-6.75-4.5M3 10l6.75 4.5M21 10l-6.75 4.5m0 0l-1.14.76a2 2 0 01-2.22 0l-1.14-.76" />
                </svg>
              </div>
            </div>
            <h2 className="text-2xl font-semibold text-grey-800">
              Check Your Email
            </h2>
            <p className="text-grey-600">
              We've sent a verification email to <span className="font-medium">{restaurant?.email}</span>
            </p>
            <p className="text-sm text-grey-500">
              Please click the link in the email to verify your account.
              If you don't see the email, check your spam folder.
            </p>
          </div>

          {/* Resend Button */}
          <div className="pt-4">
            <button
              onClick={handleResendEmail}
              disabled={resendMutation.isPending}
              className="w-full flex justify-center py-2.5 px-4 border border-transparent text-sm font-medium rounded-lg text-white bg-primary-main hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-light transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {resendMutation.isPending ? 'Sending...' : 'Resend Verification Email'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
} 