import React, { useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { DashboardLayout } from '../../../../layouts/dashboard-layout';
import { ProgressiveImage } from '../../../../components/progressive-image';
import { menuItemApi } from '../../../../services/menu-item';
import { toast } from 'react-hot-toast';
import { MenuItemDialog } from '../components/menu-item-dialog';
import { DeleteConfirmation } from '../components/menu-item-dialog/delete-confirmation';
import { MenuItem } from 'types';

export default function MenuItemDetailPage() {
  const { categoryId, id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const { 
    data,
    isLoading,
    isError,
    error 
  } = useQuery({
    queryKey: ['menuItem', categoryId, id],
    queryFn: () => menuItemApi.getById(categoryId!, id!),
    enabled: !!categoryId && !!id,
  });

  // Handle edit
  const handleEdit = () => {
    setIsEditDialogOpen(true);
  };

  const handleSubmitEdit = async (formData: Partial<MenuItem>) => {
    if (!categoryId || !id) return;

    try {
      await menuItemApi.update(categoryId, id, formData);
      
      // Invalidate and refetch
      await queryClient.invalidateQueries({ queryKey: ['menuItem', categoryId, id] });
      await queryClient.invalidateQueries({ queryKey: ['menuItems', categoryId] });
      
      setIsEditDialogOpen(false);
      toast.success('Item updated successfully');
    } catch (error) {
      toast.error('Failed to update item');
      throw error;
    }
  };

  // Handle delete
  const handleDelete = () => {
    setShowDeleteDialog(true);
  };

  const handleConfirmDelete = async () => {
    if (!categoryId || !id) return;

    try {
      await menuItemApi.delete(categoryId, id);
      
      // Show success message
      toast.success('Item deleted successfully');
      
      // Navigate back to category page
      navigate(`/menu/category/${categoryId}`);
      
      // Invalidate the category's menu items
      await queryClient.invalidateQueries({ queryKey: ['menuItems', categoryId] });
    } catch (error) {
      toast.error('Failed to delete item');
      console.error('Error deleting item:', error);
    } finally {
      setShowDeleteDialog(false);
    }
  };

  if (isLoading) {
    return (
      <DashboardLayout>
        <div className="container mx-auto p-4">
          <div className="animate-pulse">
            <div className="h-6 bg-grey-100 rounded w-1/3 mb-6" />
            <div className="bg-white rounded-xl shadow-md overflow-hidden">
              <div className="aspect-[21/9] bg-grey-100" />
              <div className="p-6">
                <div className="h-8 bg-grey-100 rounded w-1/2 mb-4" />
                <div className="space-y-3">
                  <div className="h-4 bg-grey-100 rounded w-full" />
                  <div className="h-4 bg-grey-100 rounded w-2/3" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    );
  }

  if (isError) {
    return (
      <DashboardLayout>
        <div className="container mx-auto p-4">
          <div className="bg-error-lighter text-error-main p-6 rounded-xl text-center">
            <h3 className="text-lg font-medium mb-2">Failed to load menu item</h3>
            <p className="text-sm mb-4">
              {error instanceof Error ? error.message : 'An error occurred while loading the menu item'}
            </p>
            <button 
              onClick={() => navigate(-1)}
              className="px-4 py-2 bg-error-main text-white rounded-lg hover:bg-error-dark transition-colors"
            >
              Go Back
            </button>
          </div>
        </div>
      </DashboardLayout>
    );
  }

  const { category, menuItem } = data!;

  return (
    <DashboardLayout>
      <div className="container mx-auto p-4">
        {/* Breadcrumb - Made scrollable on mobile */}
        <div className="flex items-center gap-2 text-sm text-grey-600 mb-6 overflow-x-auto whitespace-nowrap pb-2">
          <Link to="/menu" className="hover:text-primary-main transition-colors">
            Menu
          </Link>
          <span>/</span>
          <Link to={`/menu/category/${categoryId}`} className="hover:text-primary-main transition-colors">
            {category.name}
          </Link>
          <span>/</span>
          <span className="text-ellipsis overflow-hidden">{menuItem.name}</span>
        </div>

        <div className="bg-white rounded-xl shadow-md overflow-hidden">
          {/* Main Content Grid */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            {/* Left Side - Image Gallery */}
            <div className="relative align-center">
              {/* Image Gallery - Adjusted to better fit food photos */}
              <div className="aspect-square sm:aspect-[4/3] lg:aspect-square max-h-[500px] bg-gradient-to-br from-grey-50 to-grey-100 relative group">
                {menuItem.imageUrls?.length ? (
                  <>
                    <ProgressiveImage
                      media={menuItem.imageUrls[currentImageIndex]}
                      alt={menuItem.name}
                      className="w-full h-full object-contain"
                    />
                    
                    {/* Image Navigation - Made more compact */}
                    {menuItem.imageUrls.length > 1 && (
                      <>
                        <div className="absolute inset-y-0 left-0 right-0 flex items-center justify-between px-2 sm:px-4 opacity-100 sm:opacity-0 group-hover:opacity-100 transition-opacity">
                          <button 
                            onClick={() => setCurrentImageIndex(prev => 
                              prev === 0 ? menuItem.imageUrls!.length - 1 : prev - 1
                            )}
                            className="p-1 sm:p-1.5 rounded-full bg-grey-900/50 text-white hover:bg-grey-900/70 transition-colors backdrop-blur-sm"
                          >
                            <svg className="w-4 h-4 sm:w-5 sm:h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                            </svg>
                          </button>
                          <button 
                            onClick={() => setCurrentImageIndex(prev => 
                              prev === menuItem.imageUrls!.length - 1 ? 0 : prev + 1
                            )}
                            className="p-1 sm:p-1.5 rounded-full bg-grey-900/50 text-white hover:bg-grey-900/70 transition-colors backdrop-blur-sm"
                          >
                            <svg className="w-4 h-4 sm:w-5 sm:h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                            </svg>
                          </button>
                        </div>

                        {/* Image Counter - Made more compact */}
                        <div className="absolute bottom-2 left-1/2 -translate-x-1/2 px-2 py-0.5 rounded-full bg-grey-900/50 text-white backdrop-blur-sm text-xs">
                          {currentImageIndex + 1} / {menuItem.imageUrls.length}
                        </div>
                      </>
                    )}

                    {/* Thumbnail Navigation - New Addition */}
                    {menuItem.imageUrls.length > 1 && (
                      <div className="absolute bottom-0 inset-x-0 p-2 bg-gradient-to-t from-grey-900/50 to-transparent">
                        <div className="flex justify-center gap-2 overflow-x-auto pb-2">
                          {menuItem.imageUrls.map((image, index) => (
                            <button
                              key={index}
                              onClick={() => setCurrentImageIndex(index)}
                              className={`relative w-12 h-12 rounded-md overflow-hidden flex-shrink-0 transition-all ${
                                currentImageIndex === index 
                                  ? 'ring-2 ring-primary-main ring-offset-1'
                                  : 'opacity-70 hover:opacity-100'
                              }`}
                            >
                              <ProgressiveImage
                                media={image}
                                alt={`${menuItem.name} - Image ${index + 1}`}
                                className="w-full h-full object-cover"
                              />
                            </button>
                          ))}
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="w-full h-full flex items-center justify-center">
                    <svg className="w-12 h-12 text-grey-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  </div>
                )}

                {/* AR View Button - Adjusted position */}
                {menuItem.arModelUrl && (
                  <button className="absolute top-2 right-2 px-2.5 py-1.5 rounded-lg bg-primary-main text-white hover:bg-primary-dark transition-colors flex items-center gap-1.5 backdrop-blur-sm text-xs sm:text-sm">
                    <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                    </svg>
                    <span className="hidden sm:inline">View in AR</span>
                    <span className="sm:hidden">AR</span>
                  </button>
                )}
              </div>
            </div>

            {/* Right Side - Basic Info */}
            <div className="p-4 lg:pr-6 lg:py-6 lg:pl-0">
              {/* Status Badge - Moved to top */}
              <div className={`mb-4 inline-flex items-center gap-2 px-2.5 sm:px-3 py-1 sm:py-1.5 rounded-full ${
                menuItem.isActive 
                  ? 'bg-success-lighter text-success-dark'
                  : 'bg-grey-100 text-grey-600'
              }`}>
                <span className={`w-1.5 sm:w-2 h-1.5 sm:h-2 rounded-full ${
                  menuItem.isActive ? 'bg-success-main' : 'bg-grey-400'
                }`} />
                {menuItem.isActive ? 'Active' : 'Not Available'}
              </div>

              {/* Title and Price */}
              <div className="mb-4">
                <h1 className="text-2xl sm:text-3xl font-bold text-grey-900 mb-2">
                  {menuItem.name}
                </h1>
                <div className="flex items-center gap-4">
                  <span className="text-xl sm:text-2xl font-bold text-primary-main">
                    ₹{menuItem.price}
                  </span>
                  {menuItem.calories > 0 && (
                    <span className="text-sm sm:text-base text-grey-500 flex items-center gap-1.5">
                      <svg className="w-4 h-4 sm:w-5 sm:h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                      </svg>
                      {menuItem.calories} cal
                    </span>
                  )}
                </div>
              </div>

              {/* Description */}
              {menuItem.description && (
                <p className="text-grey-600 text-base sm:text-lg mb-6">
                  {menuItem.description}
                </p>
              )}

              {/* Dietary Info */}
              {(menuItem.isVegetarian || menuItem.isVegan || menuItem.isGlutenFree) && (
                <div className="flex flex-wrap gap-3 mb-6">
                  {menuItem.isVegetarian && (
                    <span className="px-3 py-1.5 rounded-full text-sm font-medium bg-success-lighter text-success-dark flex items-center gap-1.5">
                      <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                      </svg>
                      Vegetarian
                    </span>
                  )}
                  {menuItem.isVegan && (
                    <span className="px-3 py-1.5 rounded-full text-sm font-medium bg-success-lighter text-success-dark flex items-center gap-1.5">
                      <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
                      </svg>
                      Vegan
                    </span>
                  )}
                  {menuItem.isGlutenFree && (
                    <span className="px-3 py-1.5 rounded-full text-sm font-medium bg-info-lighter text-info-dark flex items-center gap-1.5">
                      <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                      </svg>
                      Gluten Free
                    </span>
                  )}
                </div>
              )}

              {/* Action Buttons - Updated */}
              <div className="flex gap-3 mt-6">
                <button 
                  onClick={handleEdit}
                  className="flex-1 px-4 sm:px-6 py-2.5 sm:py-3 bg-primary-main text-white rounded-lg hover:bg-primary-dark transition-colors shadow-md flex items-center justify-center gap-2 text-sm sm:text-base"
                >
                  <svg className="w-4 h-4 sm:w-5 sm:h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                  </svg>
                  Edit Item
                </button>
                <button 
                  onClick={handleDelete}
                  className="flex-1 px-4 sm:px-6 py-2.5 sm:py-3 bg-error-main text-white rounded-lg hover:bg-error-dark transition-colors shadow-md flex items-center justify-center gap-2 text-sm sm:text-base"
                >
                  <svg className="w-4 h-4 sm:w-5 sm:h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                  </svg>
                  Delete Item
                </button>
              </div>
            </div>
          </div>

          {/* Bottom Section - Ingredients & Allergens */}
          <div className="p-4 sm:p-6 border-t border-grey-200">
            <div className="grid sm:grid-cols-2 gap-6">
              {menuItem.ingredients && (
                <div className="space-y-2">
                  <h3 className="text-lg font-semibold text-grey-900 flex items-center gap-2">
                    <svg className="w-5 h-5 text-grey-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z" />
                    </svg>
                    Ingredients
                  </h3>
                  <p className="text-grey-600">
                    {menuItem.ingredients}
                  </p>
                </div>
              )}
              {menuItem.allergens && (
                <div className="space-y-2">
                  <h3 className="text-lg font-semibold text-error-main flex items-center gap-2">
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                    </svg>
                    Allergens
                  </h3>
                  <p className="text-error-main">
                    {menuItem.allergens}
                  </p>
                </div>
              )}
            </div>

            {/* Created & Updated Info */}
            <div className="mt-8 pt-6 border-t border-grey-200 text-xs text-grey-500 flex items-center justify-between">
              <div>
                Created: {new Date(menuItem.createdAt).toLocaleDateString()}
              </div>
              <div>
                Last Updated: {new Date(menuItem.updatedAt).toLocaleDateString()}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Dialog */}
      <MenuItemDialog
        open={isEditDialogOpen}
        onClose={() => setIsEditDialogOpen(false)}
        onSubmit={handleSubmitEdit}
        item={menuItem}
      />

      {/* Delete Confirmation Dialog */}
      <DeleteConfirmation
        isOpen={showDeleteDialog}
        itemName={menuItem.name}
        onClose={() => setShowDeleteDialog(false)}
        onConfirm={handleConfirmDelete}
      />
    </DashboardLayout>
  );
}