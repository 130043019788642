import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { DashboardLayout } from '../../../layouts/dashboard-layout';
import { CategoryHeader } from './components/category-header';
import { MenuItemCard } from './components/menu-item-card';
import { EmptyState } from './components/empty-state';
import { MenuItemDialog } from './components/menu-item-dialog';
import { DeleteConfirmation } from './components/menu-item-dialog/delete-confirmation';
import { menuItemApi } from '../../../services/menu-item';
import { toast } from 'react-hot-toast';
import type { MenuItem } from '../../../types/menu-item';

type FilterType = 'all' | 'vegetarian' | 'vegan' | 'gluten-free' | 'active' | 'inactive';

export default function CategoryPage() {
  const { categoryId } = useParams();
  const queryClient = useQueryClient();
  const [searchQuery, setSearchQuery] = useState('');
  const [activeFilter, setActiveFilter] = useState<FilterType>('all');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<MenuItem | undefined>();
  const [deleteItem, setDeleteItem] = useState<MenuItem | undefined>();

  const { 
    data,
    isLoading,
    isError,
    error,
    refetch
  } = useQuery({
    queryKey: ['menuItems', categoryId],
    queryFn: () => menuItemApi.getByCategoryId(categoryId!),
    enabled: !!categoryId,
  });

  // Handle add/edit item
  const handleAddItem = () => {
    setSelectedItem(undefined);
    setIsDialogOpen(true);
  };

  const handleEditItem = (item: MenuItem) => {
    setSelectedItem(item);
    setIsDialogOpen(true);
  };

  const handleSubmitItem = async (formData: Partial<MenuItem>) => {
    if (!categoryId) return;

    try {
      if (selectedItem) {
        // Edit existing item
        await menuItemApi.update(categoryId, selectedItem.id, formData);
      } else {
        // Add new item
        await menuItemApi.create(categoryId, formData);
      }
      
      // Close dialog and reset selected item
      setIsDialogOpen(false);
      setSelectedItem(undefined);
      
      // Invalidate and refetch
      await queryClient.invalidateQueries({ queryKey: ['menuItems', categoryId] });
      
      // Show success message
      toast.success(selectedItem ? 'Item updated successfully' : 'Item created successfully');
    } catch (error) {
      toast.error(selectedItem ? 'Failed to update item' : 'Failed to create item');
      throw error;
    }
  };

  // Update delete handler
  const handleDeleteItem = async (item: MenuItem) => {
    setDeleteItem(item);
  };

  const handleConfirmDelete = async () => {
    if (!categoryId || !deleteItem) return;

    try {
      await menuItemApi.delete(categoryId, deleteItem.id);
      
      // Invalidate and refetch
      await queryClient.invalidateQueries({ queryKey: ['menuItems', categoryId] });
      
      // Show success message
      toast.success('Item deleted successfully');
    } catch (error) {
      toast.error('Failed to delete item');
      console.error('Error deleting item:', error);
    } finally {
      setDeleteItem(undefined);
    }
  };

  // Filter menu items based on search and filters
  const filteredItems = data?.menuItems.filter(item => {
    // Search filter
    const matchesSearch = 
      item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.ingredients.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.allergens.toLowerCase().includes(searchQuery.toLowerCase());
    
    if (!matchesSearch) return false;

    // Dietary and status filters
    switch (activeFilter) {
      case 'vegetarian':
        return item.isVegetarian;
      case 'vegan':
        return item.isVegan;
      case 'gluten-free':
        return item.isGlutenFree;
      case 'active':
        return item.isActive;
      case 'inactive':
        return !item.isActive;
      default:
        return true;
    }
  });

  // Filter options configuration
  const filterOptions = [
    { id: 'all', label: 'All Items', icon: (
      <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
      </svg>
    )},
    { id: 'active', label: 'Active', icon: (
      <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
    )},
    { id: 'inactive', label: 'In Active', icon: (
      <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
    )},
    { id: 'vegetarian', label: 'Vegetarian', icon: (
      <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
      </svg>
    )},
    { id: 'vegan', label: 'Vegan', icon: (
      <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
      </svg>
    )},
    { id: 'gluten-free', label: 'Gluten Free', icon: (
      <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
      </svg>
    )},
  ] as const;

  if (isLoading) {
    return (
      <DashboardLayout>
        <div className="container mx-auto p-4">
          <div className="flex items-center gap-2 text-sm text-grey-600 mb-6">
            <Link to="/menu" className="hover:text-primary-main transition-colors">
              Menu
            </Link>
            <span>/</span>
            <span>Loading...</span>
          </div>
          
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-6">
            {[...Array(8)].map((_, index) => (
              <div key={index} className="bg-white rounded-xl shadow-md overflow-hidden animate-pulse">
                <div className="aspect-video bg-grey-100" />
                <div className="p-4">
                  <div className="h-6 bg-grey-100 rounded w-3/4 mb-2" />
                  <div className="space-y-2">
                    <div className="h-4 bg-grey-100 rounded w-full" />
                    <div className="h-4 bg-grey-100 rounded w-2/3" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </DashboardLayout>
    );
  }

  if (isError) {
    return (
      <DashboardLayout>
        <div className="container mx-auto p-4">
          <div className="flex items-center gap-2 text-sm text-grey-600 mb-6">
            <Link to="/menu" className="hover:text-primary-main transition-colors">
              Menu
            </Link>
            <span>/</span>
            <span>Error</span>
          </div>

          <div className="bg-error-lighter text-error-main p-6 rounded-xl text-center">
            <h3 className="text-lg font-medium mb-2">Failed to load menu items</h3>
            <p className="text-sm mb-4">
              {error instanceof Error ? error.message : 'An error occurred while loading menu items'}
            </p>
            <button 
              onClick={() => refetch()}
              className="px-4 py-2 bg-error-main text-white rounded-lg hover:bg-error-dark transition-colors"
            >
              Try Again
            </button>
          </div>
        </div>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <div className="container mx-auto p-4">
        <CategoryHeader 
          category={data?.category}
          itemCount={data?.menuItems.length || 0}
        />
        
        {/* Filters and Search */}
        <div className="mb-6 flex flex-col lg:flex-row gap-4 items-start lg:items-center justify-between bg-white p-4 rounded-xl shadow-sm">
          <div className="flex flex-col sm:flex-row gap-4 w-full lg:w-auto">
            {/* Search */}
            <div className="relative flex-1 max-w-md w-full">
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search menu items..."
                className="w-full pl-10 pr-4 py-2 border border-grey-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-light focus:border-primary-main"
              />
              <svg
                className="absolute left-3 top-2.5 h-5 w-5 text-grey-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>

            {/* Filter Pills */}
            <div className="flex flex-wrap gap-2">
              {filterOptions.map((filter) => (
                <button
                  key={filter.id}
                  onClick={() => setActiveFilter(filter.id as FilterType)}
                  className={`px-3 py-1.5 rounded-lg text-sm font-medium transition-colors inline-flex items-center gap-1.5 ${
                    activeFilter === filter.id
                      ? 'bg-primary-main text-white'
                      : 'bg-grey-100 text-grey-700 hover:bg-grey-200'
                  }`}
                >
                  {filter.icon}
                  {filter.label}
                </button>
              ))}
            </div>
          </div>

          {/* Add Item Button */}
          <button 
            onClick={handleAddItem}
            className="px-4 py-2 bg-primary-main text-white rounded-lg hover:bg-primary-dark transition-colors flex items-center gap-2 w-full sm:w-auto justify-center"
          >
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
            </svg>
            Add Item
          </button>
        </div>

        {/* Menu Items Grid */}
        {filteredItems?.length === 0 ? (
          <EmptyState handleAddItem={handleAddItem} />
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-6">
            {filteredItems?.map((item) => (
              <MenuItemCard 
                key={item.id} 
                item={item}
                onEdit={() => handleEditItem(item)}
                onDelete={handleDeleteItem}
              />
            ))}
          </div>
        )}

        {/* Menu Item Dialog */}
        <MenuItemDialog
          open={isDialogOpen}
          onClose={() => {
            setIsDialogOpen(false);
            setSelectedItem(undefined);
          }}
          onSubmit={handleSubmitItem}
          item={selectedItem}
        />

        {/* Delete Confirmation Dialog */}
        <DeleteConfirmation
          isOpen={!!deleteItem}
          itemName={deleteItem?.name || ''}
          onClose={() => setDeleteItem(undefined)}
          onConfirm={handleConfirmDelete}
        />
      </div>
    </DashboardLayout>
  );
} 