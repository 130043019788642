import api from './api';
import type { ApiResponse } from '../types/api';
import type { MenuItem } from '../types/menu-item';
import type { MenuCategory } from '../types/menu';

interface MenuItemsResponse {
  category: MenuCategory;
  menuItems: MenuItem[];
}

interface MenuItemData extends Partial<MenuItem> {
  categoryId: string;
}

export const menuItemApi = {
  getByCategoryId: async (categoryId: string): Promise<MenuItemsResponse> => {
    const response = await api.get<ApiResponse<MenuItemsResponse>>(`/api/v1/menu-item/${categoryId}`);
    return response.data.data;
  },

  create: async (categoryId: string, data: Partial<MenuItem>): Promise<MenuItem> => {
    const response = await api.post<ApiResponse<MenuItem>>(`/api/v1/menu-item/${categoryId}`, data);
    return response.data.data;
  },

  update: async (categoryId: string, id: string, data: Partial<MenuItem>): Promise<MenuItem> => {
    const response = await api.put<ApiResponse<MenuItem>>(`/api/v1/menu-item/${categoryId}/${id}`, data);
    return response.data.data;
  },

  delete: async (categoryId: string, id: string): Promise<void> => {
    await api.delete<ApiResponse<string>>(`/api/v1/menu-item/${categoryId}/${id}`);
  },

  getById: async (categoryId: string, id: string): Promise<{ category: MenuCategory; menuItem: MenuItem }> => {
    const response = await api.get<ApiResponse<{ category: MenuCategory; menuItem: MenuItem }>>(`/api/v1/menu-item/${categoryId}/${id}`);
    return response.data.data;
  },
}; 