import api from './api';
import imageCompression from 'browser-image-compression';
import type { Media } from '../types/media';
import type { ApiResponse } from '../types/api';

const compressImage = async (file: File) => {
  const options = {
    maxSizeMB: 10, // Increased to 1MB for better quality
    maxWidthOrHeight: 1920, // Increased to 1920px - good for modern displays
    useWebWorker: true,
    fileType: file.type,
    initialQuality: 0.8, // 80% quality - good balance between quality and size
    alwaysKeepResolution: true, // Maintain resolution if image is smaller
  };

  // If file is already small, skip compression
  if (file.size <= 1024 * 1024) { // If less than 1MB
    return file;
  }

  try {
    const compressedFile = await imageCompression(file, options);
    return compressedFile;
  } catch (error) {
    console.error('Error compressing image:', error);
    return file;
  }
};

export const uploadApi = {
  uploadImage: async (file: File): Promise<Media> => {
    // Compress the image
    const compressedFile = await compressImage(file);

    const formData = new FormData();
    // Add both original and compressed files
    formData.append('media', file); // Original file
    formData.append('compressedMedia', compressedFile); // Compressed file

    // Set proper headers for multipart/form-data
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    try {
      const response = await api.post<ApiResponse<Media>>('/api/v1/upload', formData, config);
      return response.data.data;
    } catch (error: any) {
      throw new Error(error.response?.data?.message || 'Failed to upload image');
    }
  },
}; 