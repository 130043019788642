import React from 'react';
import { Link, useLocation } from 'react-router-dom';

interface NavItem {
  title: string;
  path: string;
  icon: React.ReactNode;
}

const navItems: NavItem[] = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: (
      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
      </svg>
    ),
  },
  {
    title: 'Menu',
    path: '/menu',
    icon: (
      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
      </svg>
    ),
  },
];

interface SideNavbarProps {
  isOpen: boolean;
  isMobile: boolean;
}

export function SideNavbar({ isOpen, isMobile }: SideNavbarProps) {
  const location = useLocation();

  return (
    <aside
      className={`fixed inset-y-0 left-0 bg-white shadow-md transition-all duration-200 flex flex-col h-[100vh] overflow-y-auto
        ${isOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'} 
        ${isMobile ? 'z-50 w-64' : 'lg:w-64 lg:w-20'}
      `}
    >
      {/* Cat Animation */}
      <div className={`p-4 border-b border-grey-200 ${!isOpen && 'hidden'}`}>
        <div className="relative mx-auto w-40 h-40 overflow-hidden rounded-2xl">
          <img
            src="/cat-animation.gif"
            alt="Menu Animation"
            className="w-full h-full object-cover"
          />
        </div>
        <p className="text-center text-sm text-grey-500 mt-2">
          Manage your digital menu
        </p>
      </div>

      {/* Navigation */}
      <div className="flex-1 p-4">
        <nav className="space-y-1">
          {navItems.map((item) => {
            const isActive = location.pathname.includes(item.path);
            
            return (
              <Link
                key={item.path}
                to={item.path}
                className={`flex items-center gap-4 p-3 rounded-lg transition-colors ${
                  isActive
                    ? 'bg-primary-lighter text-primary-main'
                    : 'text-grey-600 hover:bg-grey-100'
                }`}
              >
                {item.icon}
                <span className={`${!isOpen && 'hidden'} font-medium`}>
                  {item.title}
                </span>
              </Link>
            );
          })}
        </nav>
      </div>

      {/* Branding Footer */}
      <div className={`border-t border-grey-200 p-4 ${!isOpen && 'hidden'}`}>
        <div className="flex flex-col items-center space-y-3">
          {/* Logo */}
          <img
            src="/logo.png"
            alt="Meneuar"
            className="h-8 w-auto"
          />
          
          {/* Brand Name with Gradient */}
          <div className="text-center">
            <h2 className="text-xl font-bold bg-gradient-to-r from-primary-main to-primary-dark bg-clip-text text-transparent [-webkit-background-clip:text]">
              Meneuar
            </h2>
            <p className="text-[10px] uppercase tracking-wider text-grey-500 mt-0.5">
              Digital Menu Platform
            </p>
          </div>

          {/* Version Tag */}
          <div className="px-2 py-0.5 rounded-full bg-grey-100">
            <p className="text-[10px] font-medium text-grey-600">
              v1.0.0
            </p>
          </div>
        </div>
      </div>

      {/* Collapsed Branding */}
      {!isOpen && (
        <div className="border-t border-grey-200 p-4">
          <div className="flex flex-col items-center space-y-2">
            <img
              src="/logo.png"
              alt="Meneuar"
              className="h-6 w-auto"
            />
            <div className="w-6 h-6 rounded-full bg-primary-lighter flex items-center justify-center">
              <span className="text-primary-main font-bold text-xs">M</span>
            </div>
          </div>
        </div>
      )}
    </aside>
  );
} 