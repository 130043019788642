import React from 'react';
import { RestaurantIcon } from './restaurant-icon';

interface EmptyStateProps {
  onAdd?: () => void;
}

export function EmptyState({ onAdd }: EmptyStateProps) {
  return (
    <div className="text-center py-12">
      <div className="flex justify-center">
        <div className="w-24 h-24 rounded-full bg-grey-100 flex items-center justify-center">
          <RestaurantIcon className="w-12 h-12 text-grey-400" />
        </div>
      </div>
      
      <h3 className="mt-4 text-lg font-medium text-grey-900">
        No Categories Yet
      </h3>
      
      <p className="mt-2 text-sm text-grey-600 max-w-sm mx-auto">
        Get started by creating your first menu category. Categories help organize your menu items effectively.
      </p>
      
      <button
        onClick={onAdd}
        className="mt-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-lg text-white bg-primary-main hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-light transition-colors"
      >
        <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
        </svg>
        Create First Category
      </button>
    </div>
  );
} 