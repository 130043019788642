import api from './api';
import type { Restaurant } from '../types';
import type { ApiResponse, LoginResponse } from '../types/api';

interface LoginCredentials {
  email: string;
  password: string;
}

interface RegisterData {
  name: string;
  contactPersonName: string;
  address: string;
  city: string;
  state: string;
  country: string;
  email: string;
  password: string;
}

export const restaurantApi = {
  getProfile: async (): Promise<Restaurant> => {
    const response = await api.get<ApiResponse<Restaurant>>('/api/v1/profile');
    return response.data.data;
  },

  login: async (credentials: LoginCredentials): Promise<LoginResponse> => {
    const response = await api.post<ApiResponse<LoginResponse>>('/api/v1/auth/login', credentials);
    return response.data.data;
  },

  register: async (data: RegisterData): Promise<LoginResponse> => {
    const response = await api.post<ApiResponse<LoginResponse>>('/api/v1/auth/register', data);
    return response.data.data;
  },

  verifyEmail: async (token: string): Promise<void> => {
    await api.post(`/api/v1/auth/verify-email?token=${token}`);
  },

  resendVerification: async (): Promise<void> => {
    await api.post('/api/v1/auth/resend-verify-email');
  },

  update: async (data: Partial<Restaurant>): Promise<Restaurant> => {
    const response = await api.put<ApiResponse<Restaurant>>('/api/v1/profile', data);
    return response.data.data;
  },
}; 