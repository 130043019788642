import React, { useState } from 'react';
import { DashboardLayout } from '../../layouts/dashboard-layout';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { ProgressiveImage } from '../../components/progressive-image';
import { EditProfileDialog } from './components/edit-profile-dialog';
import { Restaurant } from 'types';
import { setRestaurant } from '../../redux/slices/restaurant';
import { restaurantApi } from '../../services/restaurant';
import toast from 'react-hot-toast';

export default function ProfilePage() {
  const { restaurant } = useAppSelector((state) => state.restaurant);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const dispatch = useAppDispatch();

  if (!restaurant) return null;

  const handleEditProfile = async (data: Partial<Restaurant>) => {
    try {
      const updatedRestaurant = await restaurantApi.update(data);
      dispatch(setRestaurant(updatedRestaurant));
      toast.success('Profile updated successfully');
      setIsEditDialogOpen(false);
    } catch (error: any) {
      toast.error(error.message || 'Failed to update profile');
    }
  };

  const isProfileIncomplete = !restaurant.name || !restaurant.phone || !restaurant.address;

  if (isProfileIncomplete) {
    return (
      <DashboardLayout>
        <div className="container mx-auto p-4">
          <div className="bg-white rounded-xl shadow-lg border border-warning-light">
            <div className="p-6 sm:p-8">
              <div className="flex flex-col sm:flex-row items-center gap-6">
                <div className="w-16 h-16 rounded-full bg-warning-lighter flex items-center justify-center flex-shrink-0">
                  <svg className="w-8 h-8 text-warning-main" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                  </svg>
                </div>
                <div className="text-center sm:text-left">
                  <h1 className="text-xl font-semibold text-grey-900">Complete Your Profile</h1>
                  <p className="mt-2 text-grey-600">
                    Please complete your restaurant profile to access all features.
                    We need some basic information to get started.
                  </p>
                </div>
              </div>
              <div className="mt-8 flex flex-col sm:flex-row items-center gap-4 justify-center sm:justify-start">
                <button
                  onClick={() => setIsEditDialogOpen(true)}
                  className="flex items-center gap-2 px-6 py-2.5 bg-primary-main text-white rounded-lg hover:bg-primary-dark transition-colors w-full sm:w-auto justify-center"
                >
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                  </svg>
                  Complete Profile
                </button>
              </div>
            </div>
          </div>
        </div>
        <EditProfileDialog
          open={isEditDialogOpen}
          onClose={() => setIsEditDialogOpen(false)}
          onSubmit={handleEditProfile}
          restaurant={restaurant}
        />
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <div className="container mx-auto p-4">
        {/* Profile Header */}
        <div className="bg-white rounded-xl shadow-lg overflow-hidden mb-6">
          <div className="relative h-32 bg-gradient-to-r from-primary-dark to-primary-main">
            <div className="absolute -bottom-12 left-6 sm:left-8">
              <div className="w-24 h-24 rounded-xl overflow-hidden bg-white p-1 shadow-lg">
                {restaurant.logoUrl ? (
                  <ProgressiveImage
                    media={restaurant.logoUrl}
                    alt={restaurant.name}
                    className="w-full h-full object-cover rounded-lg"
                  />
                ) : (
                  <div className="w-full h-full flex items-center justify-center bg-primary-lighter rounded-lg">
                    <span className="text-3xl font-bold text-primary-main">
                      {restaurant.name.charAt(0)}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <button
              onClick={() => setIsEditDialogOpen(true)}
              className="absolute top-4 right-4 flex items-center gap-2 px-4 py-2 bg-white text-primary-main rounded-lg hover:bg-grey-50 transition-colors shadow-md"
            >
              <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
              </svg>
              Edit Profile
            </button>
          </div>

          {/* Profile Info */}
          <div className="pt-16 pb-6 px-6 sm:px-8">
            <div className="flex flex-col sm:flex-row sm:items-end justify-between gap-4">
              <div>
                <h1 className="text-2xl font-bold text-grey-900">{restaurant.name}</h1>
                <div className="mt-2 flex items-center gap-4">
                  <div className="flex items-center gap-2">
                    <div className={`w-2 h-2 rounded-full ${restaurant.isVerified ? 'bg-success-main' : 'bg-warning-main'
                      }`} />
                    <span className="text-sm text-grey-600">
                      {restaurant.isVerified ? 'Verified Account' : 'Pending Verification'}
                    </span>
                  </div>
                  <div className="flex items-center gap-2">
                    <div className={`w-2 h-2 rounded-full ${restaurant.isActive ? 'bg-success-main' : 'bg-warning-main'
                      }`} />
                    <span className="text-sm text-grey-600">
                      {restaurant.isActive ? 'Active' : 'Inactive'}
                    </span>
                  </div>
                </div>
              </div>
              <div className="text-sm text-grey-600">
                Member since {new Date(restaurant.createdAt).toLocaleDateString()}
              </div>
            </div>
          </div>
        </div>

        {/* Details Grid */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {/* Contact Information */}
          <div className="bg-white rounded-xl shadow-lg p-6 sm:p-8">
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-primary-lighter rounded-lg">
                <svg className="w-5 h-5 text-primary-main" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                </svg>
              </div>
              <h2 className="text-lg font-semibold text-grey-900">Contact Information</h2>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              <div>
                <label className="text-sm text-grey-600">Contact Person</label>
                <p className="mt-1 font-medium text-grey-900">{restaurant.contactPersonName}</p>
              </div>
              <div>
                <label className="text-sm text-grey-600">Phone</label>
                <p className="mt-1 font-medium text-grey-900">{restaurant.phone}</p>
              </div>
              <div className="sm:col-span-2">
                <label className="text-sm text-grey-600">Email</label>
                <p className="mt-1 font-medium text-grey-900">{restaurant.email}</p>
              </div>
            </div>
          </div>

          {/* Location Information */}
          <div className="bg-white rounded-xl shadow-lg p-6 sm:p-8">
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-primary-lighter rounded-lg">
                <svg className="w-5 h-5 text-primary-main" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
              </div>
              <h2 className="text-lg font-semibold text-grey-900">Location Details</h2>
            </div>
            <div className="space-y-6">
              <div>
                <label className="text-sm text-grey-600">Address</label>
                <p className="mt-1 font-medium text-grey-900">{restaurant.address}</p>
              </div>
              <div className="grid grid-cols-2 sm:grid-cols-4 gap-6">
                <div>
                  <label className="text-sm text-grey-600">City</label>
                  <p className="mt-1 font-medium text-grey-900">{restaurant.city}</p>
                </div>
                <div>
                  <label className="text-sm text-grey-600">State</label>
                  <p className="mt-1 font-medium text-grey-900">{restaurant.state}</p>
                </div>
                <div>
                  <label className="text-sm text-grey-600">PIN Code</label>
                  <p className="mt-1 font-medium text-grey-900">{restaurant.pincode}</p>
                </div>
                <div>
                  <label className="text-sm text-grey-600">Country</label>
                  <p className="mt-1 font-medium text-grey-900">{restaurant.country}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Edit Profile Dialog */}
        <EditProfileDialog
          open={isEditDialogOpen}
          onClose={() => setIsEditDialogOpen(false)}
          onSubmit={handleEditProfile}
          restaurant={restaurant}
        />
      </div>
    </DashboardLayout>
  );
} 