import React from 'react';

export function LoadingScreen() {
  return (
    <div className="fixed inset-0 bg-gradient-to-br from-grey-50 to-grey-200 flex flex-col items-center justify-center min-h-screen">
      <div className="flex flex-col items-center space-y-6">
        {/* Logo */}
        <img
          className="h-16 w-auto animate-pulse"
          src="/logo.png"
          alt="Meneuar"
        />
        
        {/* Brand Name */}
        <h1 className="text-4xl font-bold bg-gradient-to-r from-grey-800 to-grey-600 bg-clip-text text-transparent">
          Meneuar
        </h1>

        {/* Spinner */}
        <div className="relative">
          <div className="w-12 h-12 rounded-full border-4 border-primary-light border-t-primary-main animate-spin"></div>
          <div className="absolute inset-0 w-12 h-12 rounded-full border-4 border-primary-light opacity-30"></div>
        </div>

        {/* Loading Text */}
        <p className="text-grey-600 animate-pulse">
          Loading your restaurant...
        </p>
      </div>
    </div>
  );
} 