import React from 'react';

interface PageHeaderProps {
  onAddCategory?: () => void;
}

export function PageHeader({ onAddCategory }: PageHeaderProps) {
  return (
    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-6">
      <div>
        <h1 className="text-2xl font-bold text-grey-800">
          Menu Categories
        </h1>
        <p className="text-grey-600 mt-1">
          Manage your menu categories and items
        </p>
      </div>

      <button 
        onClick={onAddCategory}
        className="px-4 py-2 bg-primary-main text-white rounded-lg hover:bg-primary-dark transition-colors flex items-center gap-2 w-full sm:w-auto justify-center"
      >
        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
        </svg>
        Add Category
      </button>
    </div>
  );
} 