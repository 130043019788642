import React, { useEffect } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter, Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Toaster } from 'react-hot-toast';
import { store } from './redux/store';
import { ProtectedRoute } from './auth/protected-route';
import { LoginPage, RegisterPage, DashboardPage, VerifyEmailPage, MenuPage, CategoryPage } from './pages';
import { LoadingScreen } from './components/loading-screen';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { setRestaurant, startLoading, setError } from './redux/slices/restaurant';
import { restaurantApi } from './services/restaurant';
import MenuItemDetailPage from 'pages/menu/category/item';
import ProfilePage from 'pages/profile';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60, // Data will be considered fresh for 1 minute
      refetchOnWindowFocus: false, // Disable refetch on window focus
    },
  },
});

// Auth wrapper component
const AuthWrapper = React.memo(({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { restaurant, loading } = useAppSelector((state) => state.restaurant);

  useEffect(() => {
    const initialize = async () => {
      const token = window.localStorage.getItem('jwt_token');
      const isPublicRoute = ['/login', '/register'].includes(location.pathname);
      const isVerifyRoute = location.pathname === '/verify-email';

      // Skip if we already have restaurant data
      if (restaurant) {
        // Check if email is not verified and not on verify page
        if (!restaurant.isVerified && !isVerifyRoute) {
          navigate('/verify-email');
          return;
        }

        if (restaurant.name === '' && !isPublicRoute) {
          navigate('/profile');
          return;
        }

        // If verified and on public/verify route, redirect to dashboard
        if ((isPublicRoute || isVerifyRoute) && restaurant.isVerified) {
          navigate('/dashboard');
        }

        return;
      }

      // Skip if already loading
      if (loading) return;

      // No token case
      if (!token) {
        if (!isPublicRoute) {
          navigate('/login');
        }
        return;
      }

      // Has token but no restaurant data - fetch profile
      try {
        dispatch(startLoading());
        const profile = await restaurantApi.getProfile();
        dispatch(setRestaurant(profile));
        
        // After getting profile, redirect if on public route
        if (isPublicRoute) {
          navigate('/dashboard');
        }
      } catch (error) {
        console.error('Profile fetch error:', error);
        window.localStorage.removeItem('jwt_token');
        dispatch(setError('Session expired. Please login again.'));
        if (!isPublicRoute) {
          navigate('/login');
        }
      }
    };

    initialize();
  }, [dispatch, navigate, location.pathname, restaurant, loading]);

  if (loading) {
    return <LoadingScreen />;
  }

  return <>{children}</>;
});

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ReduxProvider store={store}>
        <BrowserRouter>
          <AuthWrapper>
            <Routes>
              {/* Protected Routes */}
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute>
                    <DashboardPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/profile"
                element={
                  <ProtectedRoute>
                    <ProfilePage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/menu"
                element={
                  <ProtectedRoute>
                    <MenuPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/menu/category/:categoryId"
                element={
                  <ProtectedRoute>
                    <CategoryPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/menu/category/:categoryId/item/:id"
                element={
                  <ProtectedRoute>
                    <MenuItemDetailPage />
                  </ProtectedRoute>
                }
              />
              
              {/* Public Routes */}
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/verify-email" element={<VerifyEmailPage />} />
              <Route path="/" element={<Navigate to="/dashboard" replace />} />
            </Routes>
            <Toaster position="top-right" />
          </AuthWrapper>
        </BrowserRouter>
      </ReduxProvider>
    </QueryClientProvider>
  );
} 