import React from 'react';
import { useAppSelector } from '../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';

interface TopNavbarProps {
  onMenuClick: () => void;
}

export function TopNavbar({ onMenuClick }: TopNavbarProps) {
  const navigate = useNavigate();
  const { restaurant } = useAppSelector((state) => state.restaurant);

  const handleLogout = () => {
    window.localStorage.removeItem('jwt_token');
    navigate('/login');
  };

  return (
    <nav className="bg-white shadow-md fixed top-0 right-0 left-0 lg:left-64 z-50 transition-all duration-200">
      <div className="h-16 flex items-center justify-between px-4 lg:px-6">
        {/* Left side */}
        <div className="flex items-center gap-4">
          <button
            onClick={onMenuClick}
            className="p-2 rounded-lg hover:bg-grey-100 transition-colors lg:hidden"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>

          <h1 className="text-lg lg:text-xl font-semibold text-grey-800 truncate max-w-[200px] md:max-w-md">
            {restaurant?.name}
          </h1>
        </div>

        {/* Profile Menu */}
        <div className="relative z-50">
          <Menu>
            {/* Profile Button */}
            <Menu.Button className="flex items-center gap-2 p-1.5 rounded-full hover:bg-grey-100 transition-colors focus:outline-none focus:ring-2 focus:ring-primary-light">
              <div className="relative">
                <div className="w-8 h-8 rounded-full bg-primary-lighter flex items-center justify-center">
                  <span className="text-primary-main font-semibold">
                    {restaurant?.name.charAt(0).toUpperCase()}
                  </span>
                </div>
                <div className="absolute -bottom-0.5 -right-0.5 w-3 h-3 rounded-full bg-success-main border-2 border-white" />
              </div>
            </Menu.Button>

            {/* Dropdown Menu */}
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 mt-2 w-72 bg-white rounded-xl shadow-lg py-1 ring-1 ring-black ring-opacity-5 focus:outline-none">
                {/* Profile Header */}
                <div className="px-4 py-3 border-b border-grey-200">
                  <p className="text-sm font-medium text-grey-900 line-clamp-1">
                    {restaurant?.name}
                  </p>
                  <p className="text-sm text-grey-500 mt-0.5 line-clamp-1">
                    {restaurant?.email}
                  </p>
                </div>

                {/* Menu Items */}
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => navigate('/profile')}
                        className={`${
                          active ? 'bg-grey-50' : ''
                        } flex items-center w-full px-4 py-2 text-sm text-grey-700 hover:bg-grey-50`}
                      >
                        <svg className="mr-3 h-5 w-5 text-grey-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                        </svg>
                        Profile Settings
                      </button>
                    )}
                  </Menu.Item>

                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={handleLogout}
                        className={`${
                          active ? 'bg-grey-50' : ''
                        } flex items-center w-full px-4 py-2 text-sm text-error-main hover:bg-grey-50`}
                      >
                        <svg className="mr-3 h-5 w-5 text-error-main" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                        </svg>
                        Logout
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </nav>
  );
} 