import React from 'react';

export function LoadingCategories() {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
      {[...Array(8)].map((_, index) => (
        <div 
          key={index}
          className="bg-white rounded-xl shadow-md overflow-hidden animate-pulse"
        >
          {/* Image Skeleton */}
          <div className="aspect-video bg-grey-100" />

          {/* Content Skeleton */}
          <div className="p-4">
            <div className="h-6 bg-grey-100 rounded w-3/4 mb-2" />
            <div className="space-y-2">
              <div className="h-4 bg-grey-100 rounded w-full" />
              <div className="h-4 bg-grey-100 rounded w-2/3" />
            </div>
          </div>

          {/* Actions Skeleton */}
          <div className="px-4 py-3 bg-grey-50 flex justify-end gap-2">
            <div className="h-8 w-16 bg-grey-100 rounded-lg" />
            <div className="h-8 w-16 bg-grey-100 rounded-lg" />
          </div>
        </div>
      ))}
    </div>
  );
} 