import React from 'react';
import { Link } from 'react-router-dom';
import { ProgressiveImage } from '../../../components/progressive-image';
import type { MenuCategory } from '../../../types/menu';
import { RestaurantIcon } from './restaurant-icon';

interface CategoryCardProps {
  category: MenuCategory;
  onEdit?: (category: MenuCategory) => void;
  onDelete?: (category: MenuCategory) => void;
}

export function CategoryCard({ category, onEdit, onDelete }: CategoryCardProps) {
  return (
    <Link
      to={`category/${category.id}`}
      className="block bg-white rounded-xl shadow-md hover:shadow-lg transition-all duration-200 overflow-hidden"
    >
      {/* Image Section */}
      <div className="aspect-video relative overflow-hidden bg-gradient-to-br from-grey-50 to-grey-100">
        {category.imageUrl ? (
          <ProgressiveImage
            media={category.imageUrl}
            alt={category.name}
            className="w-full h-full"
          />
        ) : (
          <div className="w-full h-full flex items-center justify-center p-6">
            <RestaurantIcon className="w-full h-full text-grey-300" />
          </div>
        )}
        
        {/* Status Badge */}
        <div className={`absolute top-3 right-3 px-2.5 py-1 rounded-full text-xs font-medium ${
          category.isActive 
            ? 'bg-success-lighter text-success-dark' 
            : 'bg-grey-100 text-grey-600'
        }`}>
          {category.isActive ? 'Active' : 'Inactive'}
        </div>
      </div>

      {/* Content Section */}
      <div className="p-4">
        <h3 className="text-lg font-semibold text-grey-900 mb-1">
          {category.name}
        </h3>
        <p className="text-sm text-grey-600 line-clamp-2">
          {category.description || 'No description provided'}
        </p>
      </div>

      {/* Actions Section */}
      <div className="px-4 py-3 bg-grey-50 flex justify-between items-center">
        <div className="flex items-center gap-2">
          <span className={`w-2 h-2 rounded-full ${category.isActive ? 'bg-success-main' : 'bg-grey-400'}`} />
          <span className="text-sm text-grey-600">
            {category.isActive ? 'Active' : 'Not Available'}
          </span>
        </div>
        <div className="flex gap-2">
          <button
            onClick={(e) => {
              e.preventDefault();
              onEdit?.(category);
            }}
            className="px-3 py-1.5 text-sm font-medium text-grey-700 hover:bg-grey-100 rounded-lg transition-colors flex items-center gap-1.5"
          >
            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
            </svg>
            Edit
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              onDelete?.(category);
            }}
            className="px-3 py-1.5 text-sm font-medium text-error-main hover:bg-error-lighter rounded-lg transition-colors flex items-center gap-1.5"
          >
            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
            </svg>
            Delete
          </button>
        </div>
      </div>
    </Link>
  );
} 