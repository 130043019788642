import { Link } from "react-router-dom";

export default function QuickActions(){
    return (
        <div className="mb-8">
          <h2 className="text-lg font-semibold text-grey-900 mb-4">Quick Actions</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            <Link 
              to="/menu"
              className="flex items-center gap-4 p-4 bg-white rounded-xl border border-grey-100 hover:border-primary-main hover:shadow-md transition-all group"
            >
              <div className="p-3 rounded-lg bg-primary-lighter group-hover:bg-primary-main transition-colors">
                <svg className="w-6 h-6 text-primary-main group-hover:text-white transition-colors" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                </svg>
              </div>
              <div>
                <h3 className="font-medium text-grey-900">Add Category</h3>
                <p className="text-sm text-grey-600">Create a new menu category</p>
              </div>
            </Link>

            <Link 
              to="/profile"
              className="flex items-center gap-4 p-4 bg-white rounded-xl border border-grey-100 hover:border-primary-main hover:shadow-md transition-all group"
            >
              <div className="p-3 rounded-lg bg-primary-lighter group-hover:bg-primary-main transition-colors">
                <svg className="w-6 h-6 text-primary-main group-hover:text-white transition-colors" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                </svg>
              </div>
              <div>
                <h3 className="font-medium text-grey-900">Edit Profile</h3>
                <p className="text-sm text-grey-600">Update restaurant details</p>
              </div>
            </Link>

            <a 
              href="mailto:support@meneuar.com"
              className="flex items-center gap-4 p-4 bg-white rounded-xl border border-grey-100 hover:border-primary-main hover:shadow-md transition-all group"
            >
              <div className="p-3 rounded-lg bg-primary-lighter group-hover:bg-primary-main transition-colors">
                <svg className="w-6 h-6 text-primary-main group-hover:text-white transition-colors" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>
              </div>
              <div>
                <h3 className="font-medium text-grey-900">Get Help</h3>
                <p className="text-sm text-grey-600">Contact support team</p>
              </div>
            </a>
          </div>
        </div>
    )
}