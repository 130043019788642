import React, { useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { DashboardLayout } from '../../layouts/dashboard-layout';
import { CategoryCard } from './components/category-card';
import { PageHeader } from './components/page-header';
import { EmptyState } from './components/empty-state';
import { LoadingCategories } from './components/loading-categories';
import { CategoryDialog } from './components/category-dialog';
import { categoryApi } from '../../services/category';
import type { MenuCategory } from '../../types/menu';
import { Media } from 'types';
import { toast } from 'react-hot-toast';
import { DeleteConfirmation } from '../../components/delete-confirmation';

export default function MenuPage() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<MenuCategory | undefined>();
  const [deleteCategory, setDeleteCategory] = useState<MenuCategory | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const { 
    data: categories,
    isLoading,
    isError,
    error,
    refetch
  } = useQuery({
    queryKey: ['categories'],
    queryFn: categoryApi.getAll,
    staleTime: 1000 * 60, // 1 minute
    refetchOnWindowFocus: false,
  });

  const queryClient = useQueryClient();

  const handleAddCategory = () => {
    setSelectedCategory(undefined); // Reset selected category
    setIsDialogOpen(true);
  };

  const handleEditCategory = (category: MenuCategory) => {
    setSelectedCategory(category);
    setIsDialogOpen(true);
  };

  const handleDeleteCategory = (category: MenuCategory) => {
    setDeleteCategory(category);
  };

  const handleConfirmDelete = async () => {
    if (!deleteCategory) return;

    try {
      setIsDeleting(true);
      await categoryApi.delete(deleteCategory.id);
      await queryClient.invalidateQueries({ queryKey: ['categories'] });
      toast.success('Category deleted successfully');
      setDeleteCategory(null);
    } catch (error) {
      toast.error('Failed to delete category');
    } finally {
      setIsDeleting(false);
    }
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedCategory(undefined);
  };

  const handleSubmit = async (data: { name: string; description: string; imageUrl: Media | null; isActive: boolean }) => {
    try {
      if (selectedCategory) {
        // Edit mode - make sure we're passing all fields
        await categoryApi.update(selectedCategory.id, {
          name: data.name,
          description: data.description,
          imageUrl: data.imageUrl,
          isActive: data.isActive, // Explicitly include isActive
        });
      } else {
        // Create mode
        await categoryApi.create(data);
      }
      // Invalidate and refetch categories
      await queryClient.invalidateQueries({ queryKey: ['categories'] });
      handleCloseDialog(); // Close the dialog after successful operation
    } catch (error) {
      toast.error('Failed to save category');
    }
  };

  if (isError) {
    return (
      <DashboardLayout>
        <div className="container mx-auto p-4">
          <PageHeader onAddCategory={handleAddCategory} />
          <div className="bg-error-lighter text-error-main p-4 rounded-lg">
            <p className="text-sm">
              {error instanceof Error ? error.message : 'Failed to load categories'}
            </p>
            <button 
              onClick={() => refetch()}
              className="mt-2 text-sm font-medium hover:text-error-dark"
            >
              Try again
            </button>
          </div>
        </div>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <div className="container mx-auto p-4">
        <PageHeader onAddCategory={handleAddCategory} />

        {isLoading ? (
          <LoadingCategories />
        ) : categories?.length === 0 ? (
          <EmptyState onAdd={handleAddCategory} />
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {categories?.map((category) => (
              <CategoryCard 
                key={category.id} 
                category={category}
                onEdit={handleEditCategory}
                onDelete={handleDeleteCategory}
              />
            ))}
          </div>
        )}

        {/* Delete Confirmation Dialog */}
        <DeleteConfirmation
          open={!!deleteCategory}
          onClose={() => setDeleteCategory(null)}
          onConfirm={handleConfirmDelete}
          title="Delete Category"
          message={`Are you sure you want to delete "${deleteCategory?.name}"? This action cannot be undone.`}
          isDeleting={isDeleting}
        />

        {/* Category Dialog */}
        <CategoryDialog 
          open={isDialogOpen}
          onClose={handleCloseDialog}
          onSubmit={handleSubmit}
          category={selectedCategory}
        />
      </div>
    </DashboardLayout>
  );
} 