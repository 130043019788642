import React, { useState, useEffect } from 'react';
import type { Media } from '../types/media';

interface ProgressiveImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  media?: Media | null;
  className?: string;
  fallback?: string;
}

export function ProgressiveImage({ 
  media, 
  className = '', 
  fallback = '/placeholder.png',
  alt = 'Image',
  ...props 
}: ProgressiveImageProps) {
  const [currentSrc, setCurrentSrc] = useState<string>('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!media) {
      setCurrentSrc(fallback);
      setLoading(false);
      return;
    }

    // Start with compressed version
    setCurrentSrc(media.compressedUrl);
    setLoading(true);

    // Load the original version
    const originalImage = new Image();
    originalImage.src = media.originalUrl;

    originalImage.onload = () => {
      setCurrentSrc(media.originalUrl);
      setLoading(false);
    };

    originalImage.onerror = () => {
      // Keep compressed version if original fails
      setLoading(false);
    };

    return () => {
      originalImage.onload = null;
      originalImage.onerror = null;
    };
  }, [media, fallback]);

  return (
    <div className={`relative overflow-hidden ${className}`}>
      {/* Blurred compressed image */}
      {loading && media && (
        <img
          src={media.compressedUrl}
          alt={alt}
          className="absolute inset-0 w-full h-full object-cover filter blur-sm scale-105"
          {...props}
        />
      )}

      {/* Main image */}
      <img
        src={currentSrc || fallback}
        alt={alt}
        className={`w-full h-full object-cover transition-opacity duration-300 ${
          loading ? 'opacity-0' : 'opacity-100'
        }`}
        {...props}
      />

      {/* Loading overlay */}
      {loading && (
        <div className="absolute inset-0 bg-grey-900/10 animate-pulse" />
      )}
    </div>
  );
} 