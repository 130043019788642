import React from 'react';
import { Link } from 'react-router-dom';
import type { MenuCategory } from '../../../../types/menu';

interface CategoryHeaderProps {
  category?: MenuCategory;
  itemCount: number;
}

export function CategoryHeader({ category, itemCount }: CategoryHeaderProps) {
  return (
    <div className="mb-6">
      <div className="flex items-center gap-2 text-sm text-grey-600 mb-2">
        <Link to="/menu" className="hover:text-primary-main transition-colors">
          Menu
        </Link>
        <span>/</span>
        <span>{category?.name || 'Loading...'}</span>
      </div>
      
      <div className="flex flex-col sm:flex-row gap-4 items-start sm:items-center justify-between">
        <div>
          <h1 className="text-2xl font-bold text-grey-800">
            {category?.name || 'Loading...'}
          </h1>
          {category?.description && (
            <p className="text-grey-600 mt-1">
              {category.description}
            </p>
          )}
        </div>
        
        <div className="flex items-center gap-3">
          <span className="px-2.5 py-1 rounded-full text-sm font-medium bg-success-lighter text-success-dark">
            {itemCount} {itemCount === 1 ? 'Item' : 'Items'}
          </span>
        </div>
      </div>
    </div>
  );
} 