import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ProgressiveImage } from '../../../../components/progressive-image';
import type { MenuItem } from '../../../../types/menu-item';

interface MenuItemCardProps {
  item: MenuItem;
  onEdit: () => void;
  onDelete: (item: MenuItem) => void;
}

export function MenuItemCard({ item, onEdit, onDelete }: MenuItemCardProps) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handlePrevImage = (e: React.MouseEvent) => {
    e.preventDefault();
    setCurrentImageIndex((prev) => 
      prev === 0 ? (item.imageUrls?.length || 1) - 1 : prev - 1
    );
  };

  const handleNextImage = (e: React.MouseEvent) => {
    e.preventDefault();
    setCurrentImageIndex((prev) => 
      prev === (item.imageUrls?.length || 1) - 1 ? 0 : prev + 1
    );
  };

  return (
    <Link 
      to={`item/${item.id}`}
      className={`block bg-white rounded-xl shadow-md hover:shadow-lg transition-all duration-200 overflow-hidden group ${
        !item.isActive ? 'opacity-75 grayscale hover:grayscale-0' : ''
      }`}
    >
      {/* Image Carousel Section */}
      <div className="aspect-video relative overflow-hidden bg-gradient-to-br from-grey-50 to-grey-100">
        {item.imageUrls?.length ? (
          <>
            {/* Image */}
            <ProgressiveImage
              media={item.imageUrls[currentImageIndex]}
              alt={item.name}
              className="w-full h-full object-cover"
            />
            
            {/* Carousel Navigation */}
            {item.imageUrls.length > 1 && (
              <>
                {/* Navigation Buttons */}
                <div className="absolute inset-y-0 left-0 right-0 flex items-center justify-between p-2 opacity-0 group-hover:opacity-100 transition-opacity">
                  <button
                    onClick={handlePrevImage}
                    className="p-1.5 rounded-full bg-grey-900/50 text-white hover:bg-grey-900/70 transition-colors backdrop-blur-sm"
                  >
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                    </svg>
                  </button>
                  <button
                    onClick={handleNextImage}
                    className="p-1.5 rounded-full bg-grey-900/50 text-white hover:bg-grey-900/70 transition-colors backdrop-blur-sm"
                  >
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                    </svg>
                  </button>
                </div>

                {/* Dots Indicator */}
                <div className="absolute bottom-2 left-0 right-0 flex justify-center gap-1.5">
                  {item.imageUrls.map((_, index) => (
                    <button
                      key={index}
                      onClick={(e) => {
                        e.stopPropagation();
                        setCurrentImageIndex(index);
                      }}
                      className={`w-1.5 h-1.5 rounded-full transition-all ${
                        index === currentImageIndex
                          ? 'bg-white w-4'
                          : 'bg-white/50 hover:bg-white/75'
                      }`}
                    />
                  ))}
                </div>
              </>
            )}
          </>
        ) : (
          <div className="w-full h-full flex items-center justify-center">
            <svg className="w-16 h-16 text-grey-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
            </svg>
          </div>
        )}

        {/* Top Badges Section */}
        <div className="absolute top-2 left-2 right-2 flex justify-between items-start">
          {/* Status & Dietary Badges */}
          <div className="flex flex-wrap gap-1.5 max-w-[70%]">
            {!item.isActive && (
              <span className="px-2 py-1 rounded-full text-xs font-medium bg-grey-900/75 text-white backdrop-blur-sm">
                Not Available
              </span>
            )}
            {item.isVegetarian && (
              <span className="px-2 py-1 rounded-full text-xs font-medium bg-success-lighter text-success-dark">
                Vegetarian
              </span>
            )}
            {item.isVegan && (
              <span className="px-2 py-1 rounded-full text-xs font-medium bg-success-lighter text-success-dark">
                Vegan
              </span>
            )}
            {item.isGlutenFree && (
              <span className="px-2 py-1 rounded-full text-xs font-medium bg-info-lighter text-info-dark">
                Gluten Free
              </span>
            )}
          </div>

          {/* AR Badge */}
          {item.arModelUrl && (
            <span className="px-2 py-1 rounded-full text-xs font-medium bg-primary-lighter text-primary-dark flex items-center gap-1">
              <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
              </svg>
              AR View
            </span>
          )}
        </div>
      </div>

      {/* Content Section */}
      <div className="p-4">
        <div className="flex justify-between items-start gap-4 mb-2">
          <div>
            <h3 className="text-lg font-semibold text-grey-900">
              {item.name}
            </h3>
            <div className="flex items-center gap-2 mt-1">
              {item.calories > 0 && (
                <span className="text-xs text-grey-500 flex items-center gap-1">
                  <svg className="w-3.5 h-3.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                  </svg>
                  {item.calories} cal
                </span>
              )}
            </div>
          </div>
          <span className="text-lg font-bold text-primary-main whitespace-nowrap">
            ₹{item.price}
          </span>
        </div>
        
        <p className="text-sm text-grey-600 line-clamp-2 mb-3">
          {item.description}
        </p>
        
        {/* Ingredients & Allergens */}
        {(item.ingredients || item.allergens) && (
          <div className="space-y-1.5 mt-3 pt-3 border-t border-grey-200">
            {item.ingredients && (
              <p className="text-xs text-grey-600">
                <span className="font-medium">Ingredients:</span>{' '}
                {item.ingredients}
              </p>
            )}
            {item.allergens && (
              <p className="text-xs text-error-main">
                <span className="font-medium">Allergens:</span>{' '}
                {item.allergens}
              </p>
            )}
          </div>
        )}
      </div>

      {/* Actions Section */}
      <div className="px-4 py-3 bg-grey-50 flex justify-between items-center">
        <div className="flex items-center gap-2">
          <span className={`w-2 h-2 rounded-full ${item.isActive ? 'bg-success-main' : 'bg-grey-400'}`} />
          <span className="text-sm text-grey-600">
            {item.isActive ? 'Active' : 'Not Available'}
          </span>
        </div>
        <div className="flex gap-2">
          <button
            onClick={(e) => {
              e.preventDefault();
              onEdit();
            }}
            className="px-3 py-1.5 text-sm font-medium text-grey-700 hover:bg-grey-100 rounded-lg transition-colors flex items-center gap-1.5"
          >
            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
            </svg>
            Edit
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              onDelete(item);
            }}
            className="px-3 py-1.5 text-sm font-medium text-error-main hover:bg-error-lighter rounded-lg transition-colors flex items-center gap-1.5"
          >
            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
            </svg>
            Delete
          </button>
        </div>
      </div>
    </Link>
  );
} 