import React, { useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { toast } from 'react-hot-toast';
import type { Restaurant } from '../../../types';

interface ShareMenuProps {
  restaurant: Restaurant;
}

export default function ShareMenu({ restaurant }: ShareMenuProps) {
  const [showCopiedTooltip, setShowCopiedTooltip] = useState(false);
  const menuUrl = `https://meneuar.com/menu/${restaurant.name.toLowerCase().replace(/\s+/g, '-')}/${restaurant.id}`;

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(menuUrl);
      setShowCopiedTooltip(true);
      toast.success('Link copied to clipboard!');
      setTimeout(() => setShowCopiedTooltip(false), 2000);
    } catch (error) {
      toast.error('Failed to copy link');
    }
  };

  const handleDownloadQR = () => {
    const svg = document.getElementById('qr-code');
    if (!svg) return;

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    canvas.width = 800;
    canvas.height = 800;

    const svgData = new XMLSerializer().serializeToString(svg);
    const svgImage = new Image();
    const logoImage = new Image();
    
    logoImage.onload = () => {
      svgImage.onload = () => {
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        
        ctx.drawImage(svgImage, 0, 0, canvas.width, canvas.height);
        
        const logoSize = canvas.width * 0.2;
        const logoX = (canvas.width - logoSize) / 2;
        const logoY = (canvas.height - logoSize) / 2;
        
        ctx.drawImage(logoImage, logoX, logoY, logoSize, logoSize);
        
        const pngUrl = canvas.toDataURL('image/png');
        const downloadLink = document.createElement('a');
        downloadLink.href = pngUrl;
        downloadLink.download = `${restaurant.name}-menu-qr.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      };

      svgImage.src = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgData)));
    };

    logoImage.src = '/logo.png';
  };

  return (
    <div className="mb-8">
      <h2 className="text-lg font-semibold text-grey-900 mb-4">Share Your Menu</h2>
      
      <div className="bg-white rounded-xl shadow-sm border border-grey-100">
        <div className="p-6 border-b border-grey-100">
          <div className="flex items-center justify-between">
            <div>
              <h3 className="font-semibold text-grey-900 text-lg">Share Your Digital Menu</h3>
              <p className="text-sm text-grey-600 mt-1">
                Let your customers access your menu easily
              </p>
            </div>
            <div className="p-2 bg-primary-lighter rounded-lg">
              <svg className="w-6 h-6 text-primary-main" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
              </svg>
            </div>
          </div>
        </div>

        <div className="p-6">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
            {/* QR Code Side */}
            <div className="flex flex-col items-center space-y-4">
              <div className="p-4 bg-white rounded-lg shadow-sm border border-grey-200">
                <QRCodeSVG
                  id="qr-code"
                  value={menuUrl}
                  size={200}
                  level="H"
                  includeMargin
                  imageSettings={{
                    src: "/logo.png",
                    x: undefined,
                    y: undefined,
                    height: 24,
                    width: 24,
                    excavate: true,
                  }}
                />
              </div>
              <button
                onClick={handleDownloadQR}
                className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-primary-main hover:text-primary-dark transition-colors"
              >
                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                </svg>
                Download QR Code
              </button>
            </div>

            {/* Link Side */}
            <div className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-grey-700 mb-2">
                  Menu URL
                </label>
                <div className="flex items-center gap-2">
                  <div className="flex-1 bg-grey-50 rounded-lg p-3 font-mono text-sm text-grey-600 truncate border border-grey-200">
                    {menuUrl}
                  </div>
                  <button
                    onClick={handleCopyLink}
                    className="p-2.5 rounded-lg bg-primary-main text-white hover:bg-primary-dark transition-colors relative"
                  >
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-12a2 2 0 00-2-2h-2M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2" />
                    </svg>
                    {showCopiedTooltip && (
                      <span className="absolute -top-8 left-1/2 -translate-x-1/2 px-2 py-1 bg-grey-900 text-white text-xs rounded whitespace-nowrap">
                        Copied!
                      </span>
                    )}
                  </button>
                </div>
              </div>

              {/* Share Tips */}
              <div className="space-y-3">
                <h4 className="font-medium text-grey-800">Share your menu:</h4>
                <div className="space-y-2">
                  <div className="flex items-start gap-3">
                    <div className="p-1.5 rounded-lg bg-success-lighter">
                      <svg className="w-4 h-4 text-success-main" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                    </div>
                    <p className="text-sm text-grey-600">
                      Print the QR code and place it on your restaurant tables
                    </p>
                  </div>
                  <div className="flex items-start gap-3">
                    <div className="p-1.5 rounded-lg bg-success-lighter">
                      <svg className="w-4 h-4 text-success-main" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                    </div>
                    <p className="text-sm text-grey-600">
                      Share the link on your social media profiles
                    </p>
                  </div>
                  <div className="flex items-start gap-3">
                    <div className="p-1.5 rounded-lg bg-success-lighter">
                      <svg className="w-4 h-4 text-success-main" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                    </div>
                    <p className="text-sm text-grey-600">
                      Add the link to your Google Business profile
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}