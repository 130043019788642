import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { useAppDispatch } from '../../redux/hooks';
import { setRestaurant } from '../../redux/slices/restaurant';
import { restaurantApi } from '../../services/restaurant';
import { BASE_URL } from 'services/api';

interface FormData {
  name: string;
  contactPersonName: string;
  address: string;
  city: string;
  state: string;
  country: string;
  email: string;
  password: string;
  confirmPassword: string;
}

export default function RegisterPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<FormData>({
    name: '',
    contactPersonName: '',
    address: '',
    city: '',
    state: '',
    country: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  useEffect(() => {
    // Get token from URL if present
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    if (token) {
      // Save token to localStorage
      window.localStorage.setItem('jwt_token', token);
      
      // Clear the URL
      window.history.replaceState({}, document.title, '/login');
      
      // Show success message
      toast.success('Successfully logged in with Google!');
      
      // Refresh the page to update auth state
      window.location.reload();
    }
  }, [location]);

  const handleGoogleLogin = () => {
    // Redirect to backend Google auth endpoint
    window.location.href = `${BASE_URL}/api/v1/auth/google`;
  };

  const registerMutation = useMutation({
    mutationFn: restaurantApi.register,
    onSuccess: (data) => {
      window.localStorage.setItem('jwt_token', data.token);
      dispatch(setRestaurant(data.restaurant));
      toast.success('Registration successful!');
      navigate('/dashboard');
    },
    onError: (error: any) => {
      toast.error(error.response?.data?.error || 'Registration failed. Please try again.');
    },
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Validate passwords match
    if (formData.password !== formData.confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }

    // Remove confirmPassword before sending to API
    const { confirmPassword, ...registerData } = formData;
    registerMutation.mutate(registerData);
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-grey-100 px-4 py-12 sm:px-6 lg:px-8 bg-gradient-to-br from-grey-50 to-grey-200">
      <div className="max-w-2xl w-full space-y-8">
        <div className="bg-white rounded-xl shadow-2xl p-8 space-y-8">
          {/* Logo and Title */}
          <div className="text-center space-y-4">
            <div className="flex flex-col items-center">
              <img
                className="h-16 w-auto"
                src="/logo.png"
                alt="Meneuar"
              />
              <h1 className="mt-4 text-4xl font-bold bg-gradient-to-r from-grey-800 to-grey-600 bg-clip-text text-transparent [-webkit-background-clip:text]">
                Meneuar
              </h1>
              <p className="mt-1 text-sm text-grey-500">
                Revolutionize your Menu
              </p>
            </div>
            <div className="pt-4">
              <h2 className="text-2xl font-semibold text-grey-800">
                Create your account
              </h2>
              <p className="mt-2 text-sm text-grey-600">
                Join thousands of restaurants already using Meneuar
              </p>
            </div>
          </div>

          {/* Registration Form */}
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Restaurant Name */}
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-grey-700">
                  Restaurant Name
                </label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  required
                  value={formData.name}
                  onChange={handleChange}
                  className="mt-1 appearance-none rounded-lg relative block w-full px-3 py-2 border border-grey-300 placeholder-grey-500 text-grey-900 focus:outline-none focus:ring-primary-main focus:border-primary-main sm:text-sm"
                  placeholder="Enter restaurant name"
                  disabled={registerMutation.isPending}
                />
              </div>

              {/* Person Name */}
              <div>
                <label htmlFor="contactPersonName" className="block text-sm font-medium text-grey-700">
                  Contact Person Name
                </label>
                <input
                  id="contactPersonName"
                  name="contactPersonName"
                  type="text"
                  required
                  value={formData.contactPersonName}
                  onChange={handleChange}
                  className="mt-1 appearance-none rounded-lg relative block w-full px-3 py-2 border border-grey-300 placeholder-grey-500 text-grey-900 focus:outline-none focus:ring-primary-main focus:border-primary-main sm:text-sm"
                  placeholder="Enter contact person name"
                  disabled={registerMutation.isPending}
                />
              </div>

              {/* Address */}
              <div className="md:col-span-2">
                <label htmlFor="address" className="block text-sm font-medium text-grey-700">
                  Address
                </label>
                <input
                  id="address"
                  name="address"
                  type="text"
                  required
                  value={formData.address}
                  onChange={handleChange}
                  className="mt-1 appearance-none rounded-lg relative block w-full px-3 py-2 border border-grey-300 placeholder-grey-500 text-grey-900 focus:outline-none focus:ring-primary-main focus:border-primary-main sm:text-sm"
                  placeholder="Enter complete address"
                  disabled={registerMutation.isPending}
                />
              </div>

              {/* City */}
              <div>
                <label htmlFor="city" className="block text-sm font-medium text-grey-700">
                  City
                </label>
                <input
                  id="city"
                  name="city"
                  type="text"
                  required
                  value={formData.city}
                  onChange={handleChange}
                  className="mt-1 appearance-none rounded-lg relative block w-full px-3 py-2 border border-grey-300 placeholder-grey-500 text-grey-900 focus:outline-none focus:ring-primary-main focus:border-primary-main sm:text-sm"
                  placeholder="Enter city"
                  disabled={registerMutation.isPending}
                />
              </div>

              {/* State */}
              <div>
                <label htmlFor="state" className="block text-sm font-medium text-grey-700">
                  State
                </label>
                <input
                  id="state"
                  name="state"
                  type="text"
                  required
                  value={formData.state}
                  onChange={handleChange}
                  className="mt-1 appearance-none rounded-lg relative block w-full px-3 py-2 border border-grey-300 placeholder-grey-500 text-grey-900 focus:outline-none focus:ring-primary-main focus:border-primary-main sm:text-sm"
                  placeholder="Enter state"
                  disabled={registerMutation.isPending}
                />
              </div>

              {/* Country */}
              <div>
                <label htmlFor="country" className="block text-sm font-medium text-grey-700">
                  Country
                </label>
                <input
                  id="country"
                  name="country"
                  type="text"
                  required
                  value={formData.country}
                  onChange={handleChange}
                  className="mt-1 appearance-none rounded-lg relative block w-full px-3 py-2 border border-grey-300 placeholder-grey-500 text-grey-900 focus:outline-none focus:ring-primary-main focus:border-primary-main sm:text-sm"
                  placeholder="Enter country"
                  disabled={registerMutation.isPending}
                />
              </div>

              {/* Email */}
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-grey-700">
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  value={formData.email}
                  onChange={handleChange}
                  className="mt-1 appearance-none rounded-lg relative block w-full px-3 py-2 border border-grey-300 placeholder-grey-500 text-grey-900 focus:outline-none focus:ring-primary-main focus:border-primary-main sm:text-sm"
                  placeholder="Enter email address"
                  disabled={registerMutation.isPending}
                />
              </div>

              {/* Password */}
              <div>
                <label htmlFor="password" className="block text-sm font-medium text-grey-700">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  value={formData.password}
                  onChange={handleChange}
                  className="mt-1 appearance-none rounded-lg relative block w-full px-3 py-2 border border-grey-300 placeholder-grey-500 text-grey-900 focus:outline-none focus:ring-primary-main focus:border-primary-main sm:text-sm"
                  placeholder="Create password"
                  disabled={registerMutation.isPending}
                />
              </div>

              {/* Confirm Password */}
              <div>
                <label htmlFor="confirmPassword" className="block text-sm font-medium text-grey-700">
                  Confirm Password
                </label>
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  required
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  className="mt-1 appearance-none rounded-lg relative block w-full px-3 py-2 border border-grey-300 placeholder-grey-500 text-grey-900 focus:outline-none focus:ring-primary-main focus:border-primary-main sm:text-sm"
                  placeholder="Confirm password"
                  disabled={registerMutation.isPending}
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                disabled={registerMutation.isPending}
                className="w-full flex justify-center py-2.5 px-4 border border-transparent text-sm font-medium rounded-lg text-white bg-primary-main hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-light transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {registerMutation.isPending ? 'Creating Account...' : 'Create Account'}
              </button>
            </div>

            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-grey-300"></div>
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-grey-500">Or continue with</span>
              </div>
            </div>

            {/* Google Sign Up */}
            <button
              type="button"
              className="w-full flex items-center justify-center gap-3 py-2.5 px-4 border border-grey-300 rounded-lg shadow-sm bg-white text-sm font-medium text-grey-700 hover:bg-grey-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-light transition-colors duration-200"
              onClick={handleGoogleLogin}
            >
              <img
                className="h-5 w-5"
                src="https://www.svgrepo.com/show/475656/google-color.svg"
                alt="Google logo"
              />
              <span>Sign up with Google</span>
            </button>

            {/* Login Link */}
            <p className="text-center text-sm text-grey-600">
              Already have an account?{' '}
              <Link
                to="/login"
                className="font-medium text-primary-main hover:text-primary-dark"
              >
                Sign in
              </Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
} 