import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { useAppDispatch } from '../../redux/hooks';
import { setRestaurant } from '../../redux/slices/restaurant';
import { restaurantApi } from '../../services/restaurant';
import { BASE_URL } from 'services/api';

export default function LoginPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
  });

  const loginMutation = useMutation({
    mutationFn: restaurantApi.login,
    onSuccess: (data) => {
      window.localStorage.setItem('jwt_token', data.token);
      dispatch(setRestaurant(data.restaurant));
      toast.success('Login successful!');
      const from = (location.state as any)?.from || '/dashboard';
      navigate(from, { replace: true });
    },
    onError: (error: any) => {
      toast.error(error.response?.data?.error || 'Login failed. Please try again.');
    },
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    loginMutation.mutate(credentials);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCredentials(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  // Handle Google OAuth redirect
  useEffect(() => {
    // Get token from URL if present
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    if (token) {
      // Save token to localStorage
      window.localStorage.setItem('jwt_token', token);
      
      // Clear the URL
      window.history.replaceState({}, document.title, '/login');
      
      // Show success message
      toast.success('Successfully logged in with Google!');
      
      // Refresh the page to update auth state
      window.location.reload();
    }
  }, [location]);

  const handleGoogleLogin = () => {
    // Redirect to backend Google auth endpoint
    window.location.href = `${BASE_URL}/api/v1/auth/google`;
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-grey-100 px-4 py-12 sm:px-6 lg:px-8 bg-gradient-to-br from-grey-50 to-grey-200">
      <div className="max-w-md w-full space-y-8">
        <div className="bg-white rounded-xl shadow-2xl p-8 space-y-8">
          {/* Logo and Title */}
          <div className="text-center space-y-4">
            <div className="flex flex-col items-center">
              <img
                className="h-16 w-auto"
                src="/logo.png"
                alt="Meneuar"
              />
              <h1 className="mt-4 text-4xl font-bold bg-gradient-to-r from-grey-800 to-grey-600 bg-clip-text text-transparent [-webkit-background-clip:text]">
                Meneuar
              </h1>
              <p className="mt-1 text-sm text-grey-500">
                Revolutionize your Menu
              </p>
            </div>
            <div className="pt-4">
              <h2 className="text-2xl font-semibold text-grey-800">
                Welcome back
              </h2>
              <p className="mt-2 text-sm text-grey-600">
                Please sign in to your restaurant account
              </p>
            </div>
          </div>

          {/* Login Form */}
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div className="rounded-md space-y-4">
              <div>
                <label htmlFor="email" className="sr-only">
                  Email address
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  value={credentials.email}
                  onChange={handleChange}
                  className="appearance-none rounded-lg relative block w-full px-3 py-2 border border-grey-300 placeholder-grey-500 text-grey-900 focus:outline-none focus:ring-primary-main focus:border-primary-main focus:z-10 sm:text-sm"
                  placeholder="Email address"
                  disabled={loginMutation.isPending}
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  value={credentials.password}
                  onChange={handleChange}
                  className="appearance-none rounded-lg relative block w-full px-3 py-2 border border-grey-300 placeholder-grey-500 text-grey-900 focus:outline-none focus:ring-primary-main focus:border-primary-main focus:z-10 sm:text-sm"
                  placeholder="Password"
                  disabled={loginMutation.isPending}
                />
              </div>
            </div>

            <div className="flex items-end justify-end">
              <div className="text-sm">
                <a href="#" className="font-medium text-primary-main hover:text-primary-dark">
                  Forgot your password?
                </a>
              </div>
            </div>

            <div>
              <button
                type="submit"
                disabled={loginMutation.isPending}
                className="group relative w-full flex justify-center py-2.5 px-4 border border-transparent text-sm font-medium rounded-lg text-white bg-primary-main hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-light transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {loginMutation.isPending ? 'Signing in...' : 'Sign in'}
              </button>
            </div>

            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-grey-300"></div>
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-grey-500">Or continue with</span>
              </div>
            </div>

            {/* Google Sign In */}
            <button
              type="button"
              className="w-full flex items-center justify-center gap-3 py-2.5 px-4 border border-grey-300 rounded-lg shadow-sm bg-white text-sm font-medium text-grey-700 hover:bg-grey-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-light transition-colors duration-200"
              onClick={handleGoogleLogin}
            >
              <svg className="w-5 h-5" viewBox="0 0 24 24">
                <path
                  // fill="currentColor"
                  d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                  fill="#4285F4"
                />
                <path
                  // fill="currentColor"
                  d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                  fill="#34A853"
                />
                <path
                  // fill="currentColor"
                  d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                  fill="#FBBC05"
                />
                <path
                  // fill="currentColor"
                  d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                  fill="#EA4335"
                />
              </svg>
              Continue with Google
            </button>

            {/* Register Link */}
            <p className="text-center text-sm text-grey-600">
              Don't have an account?{' '}
              <Link
                to="/register"
                className="font-medium text-primary-main hover:text-primary-dark"
              >
                Register now
              </Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
} 