import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { Restaurant } from '../../types';

interface RestaurantState {
  restaurant: Restaurant | null;
  loading: boolean;
  error: string | null;
}

const initialState: RestaurantState = {
  restaurant: null,
  loading: false,
  error: null,
};

const restaurantSlice = createSlice({
  name: 'restaurant',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.loading = true;
      state.error = null;
    },
    stopLoading: (state) => {
      state.loading = false;
    },
    setRestaurant: (state, action: PayloadAction<Restaurant>) => {
      state.restaurant = action.payload;
      state.loading = false;
      state.error = null;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    clearRestaurant: (state) => {
      state.restaurant = null;
      state.loading = false;
      state.error = null;
    },
  },
});

export const { startLoading, setRestaurant, setError, clearRestaurant, stopLoading } = restaurantSlice.actions;
export default restaurantSlice.reducer; 